import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  take,
} from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import {
  selectAllEntities,
  selectEntitiesMap,
} from '@collections/entities/store/entities.selectors';
import {
  selectPxDFactory,
  selectPxDsMap,
} from '@collections/pxds/store/pxds.selectors';
import { legendItems } from '@common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import {
  AdminUserPxdCell,
  AdminUserResponsibility,
  UserDetails,
  UserPxD,
} from '@models/userDetails';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { userRolesValidator } from './user-roles-validator';
import { UserResponsibilitiesComponent } from '../user-responsibilities/user-responsibilities.component';
import { PxdCellsLegendComponent } from '../../../common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import { AdminUserPxdGridCellComponent } from '../../../common/pxd-grid/admin-user-pxd-grid-cell/admin-user-pxd-grid-cell.component';
import { AdminPxdGridCellComponent } from '../../../common/pxd-grid/admin-pxd-grid-cell/admin-pxd-grid-cell.component';
import { PxDGridComponent } from '../../../common/pxd-grid/pxd-grid.component';
import { MatIconModule } from '@angular/material/icon';
import { EntityMultiSelectorComponent } from '../../../common/entity-multiselector/entity-multi-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-edit-user-form',
    templateUrl: './edit-user-form.component.html',
    styleUrls: ['./edit-user-form.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        NgFor,
        MatLegacyOptionModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        EntityMultiSelectorComponent,
        MatIconModule,
        PxDGridComponent,
        AdminPxdGridCellComponent,
        AdminUserPxdGridCellComponent,
        PxdCellsLegendComponent,
        UserResponsibilitiesComponent,
        AsyncPipe,
    ],
})
export class EditUserFormComponent implements OnInit {
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store
  ) {}

  private readonly destroyRef = inject(DestroyRef);

  @Input() showEntitySelector = true;

  @Input() enablePrincipalSelection = true;

  @Input() allowRequestingAdminRole = true;

  @Input() set userCanBeMadeInActive(value: boolean) {
    if (value) {
      this.formGroup
        .get('isActive')
        .enable({ emitEvent: true, onlySelf: true });
    } else {
      this.formGroup
        .get('isActive')
        .disable({ emitEvent: true, onlySelf: true });
    }
  }

  @Input() set data(value: UserDetails) {
    if (
      !(
        this.checkIfAnyPendingCTRs(value?.pxds || []) ||
        this.checkIfPrincipalInAnyPxd(value?.pxds || [])
      )
    ) {
      this.formGroup
        .get('businessSegmentId')
        .disable({ emitEvent: true, onlySelf: true });
    } else {
      this.formGroup
        .get('businessSegmentId')
        .enable({ emitEvent: true, onlySelf: true });
    }

    this.formGroup.patchValue({
      ...(value || {}),
      userId: !value?.userId ? 0 : value.userId,
    });
    this.formGroup.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  private _originalAssignedPxDs: UserPxD[];

  @Input() set orginalAssignedPxDs(value: AdminUserPxdCell[]) {
    this._originalAssignedPxDs = value || [];
    this.assignedPxDs$.next(
      this._originalAssignedPxDs.map((pxd) => ({
        ...pxd,
        deselectionDisabled: pxd.pendingCTRs || pxd.isPrincipal,
        principalDeselectionDisabled: pxd.isPrincipal,
      }))
    );
  }

  @Input() set allowEditingEmail(value: boolean) {
    if (value) {
      this.formGroup.get('email').enable();
    } else {
      this.formGroup.get('email').disable();
    }
  }

  @Output() dataChange = defer(() =>
    combineLatest([this.userDataChange$, this.assignedPxDsChange$])
  ).pipe(
    distinctUntilChanged(),
    map(([userData, assignedPxDs]) => ({
      ...userData,
      pxds: assignedPxDs.map(({ pxdId, entityId, isPrincipal }) => ({
        pxdId,
        entityId,
        isPrincipal,
      })),
    }))
  );

  @Output() public valid = defer(() =>
    combineLatest([this.assignedPxDs$, this.formGroup.valueChanges])
  ).pipe(map(() => this.formGroup.valid));

  private readonly assignedPxDs$ = new BehaviorSubject<AdminUserPxdCell[]>([]);

  public selectedEntitiesIds$ = new BehaviorSubject<number[]>([]);

  private readonly entityIdValue$ = this.createFormInputValue('entityId').pipe(
    map((entityId) => parseInt(entityId, 10))
  );

  private readonly enforcePrincipalSelection$ = combineLatest([
    this.selectedEntitiesIds$,
    this.entityIdValue$,
  ]).pipe(
    map(([selectedEntitiesIds, userEntityId]) =>
      selectedEntitiesIds.some((entityId) => userEntityId !== entityId)
    ),
    shareReplay(1)
  );

  public pxdsByEntity$ = this.assignedPxDs$.pipe(
    map((assignedPxDs) =>
      assignedPxDs.reduce(
        (r, pxd) => ({
          ...r,
          [pxd.entityId]: { pxdsCount: (r[pxd.entityId]?.pxdsCount || 0) + 1 },
        }),
        {}
      )
    )
  );

  public canChangeSegment: boolean;

  public entitiesOptions$ = this.store.select(selectAllEntities);

  public businessSegmentsOptions$ = this.store.select(
    selectAllActiveBusinessSegments
  );

  public legendItems = [
    legendItems.WITH_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITH_MASTERDATA_WITHOUT_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_WITH_PRINCIPALS,
    legendItems.INACTIVE,
  ];

  public formGroup = this.formBuilder.group(
    {
      firstName: ['', [Validators.required, Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.maxLength(25)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Za-z0-9._%+-]+@(technipfmc|external.technipfmc)+[.]com$'
          ),
          Validators.maxLength(100),
        ],
      ],
      entityId: [null, Validators.required],
      businessSegmentId: [1, Validators.required],
      isActive: [false],
      isRequestor: [false],
      isEngineer: [false],
      isAdmin: [false],
      userId: [null],
    },
    { validators: userRolesValidator }
  );

  private readonly userDataChange$ = this.formGroup.valueChanges.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    map(() => this.formGroup.getRawValue())
  );

  public assignedPxDsChange$ = this.assignedPxDs$.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );

  public allUserResponsibilities$ = combineLatest([
    this.assignedPxDs$,
    this.store.select(selectPxDsMap),
    this.store.select(selectEntitiesMap),
  ]).pipe(
    map(([assignedPxDs, pxdMap, entitiesMap]) =>
      assignedPxDs.map(
        (pxd) =>
          ({
            ...pxd,
            pxdName: pxdMap[pxd.pxdId].shortName,
            entity: entitiesMap[pxd.entityId].entityName,
          } as AdminUserResponsibility)
      )
    )
  );

  private readonly groupedAssignedPxds$ = combineLatest([
    this.assignedPxDsChange$,
    this.enforcePrincipalSelection$,
  ]).pipe(
    map(([assignedPxDs, enforcePrincipalSelection]) =>
      Object.values(_.groupBy(assignedPxDs, 'pxdId')).map(
        (pxds: AdminUserPxdCell[]) => ({
          pxdId: pxds[0].pxdId,
          productId: pxds[0].productId,
          disciplineId: pxds[0].disciplineId,
          enforcePrincipalSelection,
          pxds,
        })
      )
    ),
    shareReplay(1)
  );

  public filteredAssignedPxds$ = combineLatest([
    this.groupedAssignedPxds$,
    this.selectedEntitiesIds$,
  ]).pipe(
    map(([groups, selectedEntitiesIds]) =>
      groups
        .filter(
          (group) =>
            selectedEntitiesIds.length > 0 &&
            selectedEntitiesIds.every((entityId) =>
              group.pxds.find((pxd) => pxd.entityId === entityId)
            )
        )
        .map((group) => {
          const pxds = group.pxds.filter((pxd) =>
            selectedEntitiesIds.includes(pxd.entityId)
          );

          return {
            ...group,
            isPrincipal: pxds.some(({ isPrincipal }) => isPrincipal),
            deselectionDisabled: pxds.some(
              ({ deselectionDisabled }) => deselectionDisabled
            ),
            principalDeselectionDisabled: pxds.some(
              ({ principalDeselectionDisabled }) => principalDeselectionDisabled
            ),
            pxds,
          };
        })
    ),
    shareReplay(1)
  );

  public ngOnInit(): void {
    this.entityIdValue$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((entityId) => {
        this.assignedPxDs$.next(
          this.assignedPxDs$.value.filter(
            (pxd) => pxd.isPrincipal || pxd.entityId === entityId
          )
        );

        this.selectedEntitiesIds$.next(entityId ? [entityId] : []);
      });
  }

  private checkIfAnyPendingCTRs(pxds: UserPxD[]) {
    return pxds.some((pxd: UserPxD) => pxd.pendingCTRs);
  }

  private checkIfPrincipalInAnyPxd(pxds: UserPxD[]) {
    return pxds.some((pxd: UserPxD) => pxd.isPrincipal);
  }

  public assignPxDs({ pxdId, isPrincipal }) {
    combineLatest([
      this.selectedEntitiesIds$,
      this.store.select(selectPxDFactory(pxdId)),
      this.entityIdValue$,
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([selectedEntitiesIds, pxd, userEntityId]) => {
        const pxds = this.assignedPxDs$.value.filter(
          (assignedPxD) =>
            assignedPxD.pxdId !== pxdId ||
            !selectedEntitiesIds.includes(assignedPxD.entityId)
        );
        this.assignedPxDs$.next(
          _.uniqBy(
            [
              ...pxds,
              ...selectedEntitiesIds.map((entityId) => {
                const orginalAssignedPxD = this._originalAssignedPxDs.find(
                  (item) => item.pxdId === pxdId && item.entityId === entityId
                );
                return {
                  entityId,
                  pxdId,
                  productId: pxd.productId,
                  disciplineId: pxd.disciplineId,
                  isPrincipal:
                    isPrincipal ||
                    orginalAssignedPxD?.isPrincipal ||
                    userEntityId !== entityId,
                  pendingCTRs: orginalAssignedPxD?.pendingCTRs || false,
                  deselectionDisabled:
                    orginalAssignedPxD?.isPrincipal ||
                    orginalAssignedPxD?.pendingCTRs,
                  principalDeselectionDisabled:
                    orginalAssignedPxD?.isPrincipal ||
                    userEntityId !== entityId,
                };
              }),
            ],
            ({ pxdId, entityId }) => `${pxdId}-${entityId}`
          )
        );
      });
  }

  public unassignPxDs(pxdId: number) {
    this.selectedEntitiesIds$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedEntitiesIds) => {
        const pxds = this.assignedPxDs$.value;
        this.assignedPxDs$.next(
          pxds.filter(
            (pxd) =>
              pxd.deselectionDisabled ||
              pxd.pxdId !== pxdId ||
              !selectedEntitiesIds.includes(pxd.entityId)
          )
        );
      });
  }

  public unassignResponsibility({ pxdId, entityId }) {
    const pxds = this.assignedPxDs$.value;
    this.assignedPxDs$.next(
      pxds.filter(
        (pxd) =>
          pxd.deselectionDisabled ||
          pxd.pxdId !== pxdId ||
          pxd.entityId !== entityId
      )
    );
  }

  private createFormInputValue(formControlName: string) {
    return defer(() =>
      this.formGroup
        .get(formControlName)
        .valueChanges.pipe(
          startWith(this.formGroup.get(formControlName).value),
          distinctUntilChanged()
        )
    ).pipe(shareReplay(1));
  }
}
