import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';

import { IPxD } from '@models/pxd';

@Component({
    selector: 'app-admin-pxd-grid-cell',
    templateUrl: './admin-pxd-grid-cell.component.html',
    styleUrls: ['./admin-pxd-grid-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class AdminPxdGridCellComponent implements OnChanges {
  @Input() public pxd: IPxD = null;

  @Input()
  @HostBinding('class.selected')
  public selected = false;

  @HostBinding('class.pxd-disabled')
  public disabled = false;

  @HostBinding('class.pxd-with-master-data')
  public withMasterdata = false;

  @HostBinding('class.pxd-without-master-data')
  public withoutMasterdata = false;

  @HostBinding('class.pxd-with-principals')
  public withPrincipals = false;

  @HostBinding('class.pxd-without-principals')
  public withoutPrincipals = false;

  public ngOnChanges() {
    if (this.pxd) {
      this.withMasterdata = this.pxd.hasMasterData;
      this.withoutMasterdata = !this.withMasterdata;
      this.withPrincipals = this.pxd.hasPrincipals;
      this.withoutPrincipals = !this.withPrincipals;
      this.disabled = !this.pxd.isActive;
    }
  }
}
