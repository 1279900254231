import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserAvatarComponent } from './user-avatar.component';

const components = [
  UserAvatarComponent
]

@NgModule({
    exports: components,
    imports: [
        CommonModule,
        ...components
    ]
})
export class UserAvatarModule { }
