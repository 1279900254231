import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { postCommentAction } from '@collections/event-log/store/event-log.actions';
import {
  selectCurrentRouteParams,
  selectEventLogContext,
} from '@core/store/core.selectors';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';

@Component({
    selector: 'app-reply-comment',
    templateUrl: './reply-comment.component.html',
    styleUrls: ['./reply-comment.component.scss'],
    standalone: true,
    imports: [UserAvatarComponent, ReactiveFormsModule],
})
export class ReplyCommentComponent implements AfterViewInit {
  constructor(private readonly store: Store) {}

  @Input() public author: string;
  @Input() public signature: string;
  @Input() public avatar: string;
  @Input() public to: string;
  @Input() public parentId: number;

  @Output() public onCloseReply = new EventEmitter();

  public messageFormControl = new FormControl();

  private readonly subscription = new Subscription();

  private readonly eventLogContext$ = this.store.select(selectEventLogContext);

  @ViewChild('input') inputElement: ElementRef;

  public sendReply() {
    const message = this.messageFormControl.getRawValue();

    if (message.trim() !== '') {
      this.subscription.add(
        combineLatest([
          this.store.select(selectCurrentRouteParams),
          this.eventLogContext$,
        ])
          .pipe(take(1))
          .subscribe(([params, context]) => {
            this.store.dispatch(
              postCommentAction({
                payload: {
                  context: context.name,
                  id: params[context.keyName],
                  message,
                  parentId: this.parentId,
                },
              })
            );
            this.closeReply();
          })
      );
    }
  }

  public closeReply() {
    this.onCloseReply.emit();
  }

  public inputControl(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.sendReply();
        event.stopPropagation();
        break;
      case 'Esc':
        this.closeReply();
        event.stopPropagation();
        event.preventDefault();
        break;
    }
  }

  public ngAfterViewInit() {
    this.inputElement.nativeElement.focus();
  }
}
