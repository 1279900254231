import { Injectable } from '@angular/core';
import { AssumptionsAndExclusionsApiService } from '@collections/assumptions-and-exclusions/assumptions-and-exclusions-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  getAssumptionsAndExclusionsAction,
  getAssumptionsAndExclusionsFailureAction,
  getAssumptionsAndExclusionsSuccessAction,
  updateCtrAssumptionsAction,
  updateCtrAssumptionsFailureAction,
  updateCtrAssumptionsSuccessAction,
  updateCtrExclusionsAction,
  updateCtrExclusionsFailureAction,
  updateCtrExclusionsSuccessAction,
  updateCtrScopeAssumptionsAction,
  updateCtrScopeAssumptionsFailureAction,
  updateCtrScopeAssumptionsSuccessAction,
  updateCtrScopeEquipmentAssumptionsAction,
  updateCtrScopeEquipmentAssumptionsSuccessAction,
  updateCtrScopeEquipmentExclusionsAction,
  updateCtrScopeEquipmentExclusionsSuccessAction,
  updateCtrScopeExclusionsAction,
  updateCtrScopeExclusionsFailureAction,
  updateCtrScopeExclusionsSuccessAction,
} from '@collections/assumptions-and-exclusions/store/assumptions-and-exclusions.actions';
import { selectCTRAssumptionsAndExclusionsFactory } from '@collections/assumptions-and-exclusions/store/assumptions-and-exclusions.selectors';

@Injectable()
export class AssumptionsAndExclusionsEffects {
  public getAssumptionsAndExclusions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssumptionsAndExclusionsAction),
      switchMap((action) =>
        this.store
          .select(
            selectCTRAssumptionsAndExclusionsFactory(action.payload.ctrId)
          )
          .pipe(
            switchMap((assumptionsAndExclusionsFromStore) =>
              !assumptionsAndExclusionsFromStore
                ? this.assumptionsAndExclusionsApiService
                    .getAssumptionsAndExclusions(action.payload.ctrId)
                    .pipe(
                      map((assumptionsAndExclusions) =>
                        getAssumptionsAndExclusionsSuccessAction({
                          trigger: action,
                          context:
                            'CTRAssumptionsAndExclusionsEffects::getAssumptionsAndExclusions$',
                          payload: {
                            ...assumptionsAndExclusions,
                            ctrId: action.payload.ctrId,
                          },
                        })
                      ),
                      catchError(() =>
                        of(
                          getAssumptionsAndExclusionsFailureAction({
                            trigger: action,
                            context:
                              'CTRAssumptionsAndExclusionsEffects::getAssumptionsAndExclusions$',
                          })
                        )
                      )
                    )
                : EMPTY
            )
          )
      )
    )
  );

  public getAssumptionsAndExclusionsAfterUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        updateCtrAssumptionsSuccessAction,
        updateCtrScopeAssumptionsSuccessAction,
        updateCtrScopeEquipmentAssumptionsSuccessAction,
        updateCtrExclusionsSuccessAction,
        updateCtrScopeExclusionsSuccessAction,
        updateCtrScopeEquipmentExclusionsSuccessAction
      ),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .getAssumptionsAndExclusions(action.payload.ctrId)
          .pipe(
            map((assumptionsAndExclusions) =>
              getAssumptionsAndExclusionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::getAssumptionsAndExclusionsAfterUpdate$',
                payload: {
                  ...assumptionsAndExclusions,
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                getAssumptionsAndExclusionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::getAssumptionsAndExclusionsAfterUpdate$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrAssumptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrAssumptionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrAssumptions(action.payload.ctrId, action.payload.text)
          .pipe(
            map(() =>
              updateCtrAssumptionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrAssumptions$',
                payload: { ctrId: action.payload.ctrId },
              })
            ),
            catchError(() =>
              of(
                updateCtrAssumptionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrScopeAssumptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrScopeAssumptionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrScopeAssumptions(
            action.payload.ctrScopeId,
            action.payload.text
          )
          .pipe(
            map(() =>
              updateCtrScopeAssumptionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrScopeAssumptions$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                updateCtrScopeAssumptionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrScopeAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrScopeEquipmentAssumptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrScopeEquipmentAssumptionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrScopeEquipmentAssumptions(
            action.payload.ctrScopeId,
            action.payload.equipmentId,
            action.payload.text
          )
          .pipe(
            map(() =>
              updateCtrScopeEquipmentAssumptionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrScopeEquipmentAssumptions$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                updateCtrAssumptionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrScopeEquipmentAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrExclusions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrExclusionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrExclusions(action.payload.ctrId, action.payload.text)
          .pipe(
            map(() =>
              updateCtrExclusionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrAssumptions$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                updateCtrExclusionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrScopeExclusions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrScopeExclusionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrScopeExclusions(
            action.payload.ctrScopeId,
            action.payload.text
          )
          .pipe(
            map(() =>
              updateCtrScopeExclusionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrScopeAssumptions$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                updateCtrScopeExclusionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrScopeAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  public updateCtrScopeEquipmentExclusions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCtrScopeEquipmentExclusionsAction),
      switchMap((action) =>
        this.assumptionsAndExclusionsApiService
          .updateCtrScopeEquipmentExclusions(
            action.payload.ctrScopeId,
            action.payload.equipmentId,
            action.payload.text
          )
          .pipe(
            map(() =>
              updateCtrScopeEquipmentExclusionsSuccessAction({
                trigger: action,
                context:
                  'CTRAssumptionsAndExclusionsEffects::updateCtrScopeEquipmentAssumptions$',
                payload: {
                  ctrId: action.payload.ctrId,
                },
              })
            ),
            catchError(() =>
              of(
                updateCtrExclusionsFailureAction({
                  trigger: action,
                  context:
                    'CTRAssumptionsAndExclusionsEffects::updateCtrScopeEquipmentAssumptions$',
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly assumptionsAndExclusionsApiService: AssumptionsAndExclusionsApiService,
    private readonly store: Store
  ) {}
}
