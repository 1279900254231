import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

export interface DialogData {
  title?: string;
  message: string;
  additionalValue?: string;
  rejectionButtonLabel?: string;
  confirmationButtonLabel?: string;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatDialogModule,
        NgFor,
        MatButtonModule,
    ],
})
export class ConfirmDialogComponent {
  dialogData: DialogData;
  title: string;
  message: string;
  additionalValue: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
