import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, SlicePipe } from '@angular/common';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [UserAvatarComponent, NgIf, MatButtonModule, MatIconModule, SlicePipe]
})
export class MessageComponent {
  @Input() public author: string;
  @Input() public signature: string;
  @Input() public avatar: string;
  @Input() public message: string;
  @Input() public date: Date;
  @Input() public type: 'system' | 'comment' = 'system';

  @Output() public reply = new EventEmitter();

  public limit = true;

  public charLimit() {
    if (this.limit) {
      const index = this.message.indexOf(' ', 120);
      return index === -1 ? 120 : index;
    }
    return this.message.length
  }

  public onReply() {
    this.reply.emit()
  }
}
