import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { AdminBlock } from '@models/admin-block';
import { IPxD } from '@models/pxd';

import { MasterdataTableService } from '../masterdata-table.service';
import {
  openAddBlockAction,
  openReorderPxdBlocksAction,
} from '../store/admin-activities.actions';
import { MasterdataBlockComponent } from '../masterdata-block/masterdata-block.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, AsyncPipe, I18nPluralPipe } from '@angular/common';

@Component({
    selector: 'app-masterdata-table',
    templateUrl: './masterdata-table.component.html',
    styleUrls: ['./masterdata-table.component.scss'],
    providers: [MasterdataTableService],
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        NgFor,
        MatSlideToggleModule,
        ReactiveFormsModule,
        FormsModule,
        MasterdataBlockComponent,
        AsyncPipe,
        I18nPluralPipe,
    ],
})
export class MasterdataTableComponent implements OnChanges {
  @Input() public pxd: IPxD;

  public readonly blocksLabelMap = {
    '=0': '0 blocks',
    '=1': '1 block',
    other: '# blocks',
  };

  public readonly scopesLabelMap = {
    '=0': '0 scopes',
    '=1': '1 scope',
    other: '# scopes',
  };

  public readonly activitiesLabelMap = {
    '=0': '0 activities',
    '=1': '1 activity',
    other: '# activities',
  };

  constructor(
    public readonly tableService: MasterdataTableService,
    private readonly store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.pxd) {
      this.tableService.selectPxD(this.pxd);
    }
  }

  public openReorderBlocks() {
    this.store.dispatch(
      openReorderPxdBlocksAction({
        context: 'MasterdataTableComponent::openReorderBlock',
        payload: { ctrId: this.pxd.id },
      })
    );
  }

  public openAddBlock() {
    this.store.dispatch(
      openAddBlockAction({
        context: 'MasterdataTableComponent::openAddBlock',
        payload: {
          ctrId: this.pxd.id,
        },
      })
    );
  }

  public trackByBlock(index: number, block: AdminBlock) {
    return block.blockId;
  }
}
