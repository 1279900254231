import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { environment } from '../environments/environment';

import { FEATURE_KEY as USERS_FEATURE_KEY } from '@collections/users/store/users.reducer';
import { AppConfigService } from './app-config.service';
export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

const restoredUser: { currentUserId: number } = JSON.parse(
  localStorage.getItem(USERS_FEATURE_KEY)
) || {
  currentUserId: -1,
};
const googleAnalyticModules =
  environment.gTag.Key !== ''
    ? [
        NgxGoogleAnalyticsModule.forRoot(environment.gTag.Key, [
          {
            command: 'config',
            values: [
              environment.gTag.Key,
              { debug_mode: environment.gTag.EnableDebugMode },
              { user_id: restoredUser.currentUserId },
            ],
          },
        ]),
        NgxGoogleAnalyticsRouterModule,
      ]
    : [];
