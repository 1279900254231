import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AdminActivity } from '@models/admin-activity';

import { getActivitiesAutocompletionDataAction } from '../store/admin-activities.actions';
import { MatButtonModule } from '@angular/material/button';
import { EditActivityFormComponent } from '../edit-activity-form/edit-activity-form.component';

export interface EditActivityDialogData {
  title: string;
  actionLabel: string;
  activityData: Partial<AdminActivity>;
  copyFrom: number;
}

@Component({
    selector: 'app-edit-activity-dialog',
    templateUrl: './edit-activity-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        EditActivityFormComponent,
        MatButtonModule,
    ],
})
export class EditActivityDialogComponent {
  public title = this.data.title;

  public actionLabel = this.data.actionLabel;

  public activityData: Partial<AdminActivity> = {
    ...this.data.activityData,
  };

  public canSave = false;

  public copyMode = !!this.data.copyFrom;

  public canChangePath = !this.data.activityData.isUsed || this.copyMode;

  public originalScopeId = this.data.activityData.scopeId;

  public originalActivityCode = this.data.activityData.activityCode;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditActivityDialogData,
    private readonly store: Store
  ) {
    this.store.dispatch(
      getActivitiesAutocompletionDataAction({
        context: 'EditActivityDialogComponent::constructor',
      })
    );
  }

  public updateState(valid: boolean) {
    const changed = Object.entries(this.activityData).some(
      ([key, value]) => this.data.activityData[key] !== value
    );

    this.canSave = (changed || this.copyMode) && valid;
  }
}
