import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LiveSaveSnackbarService } from './live-save-snackbar.service';

enum SavingState {
  IDLE,
  SAVING,
  SAVED,
  FAILED,
}

@Component({
  selector: 'app-live-save-snackbar',
  templateUrl: './live-save-snackbar.component.html',
  styleUrls: ['./live-save-snackbar.component.scss'],
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase, MatIconModule],
})
export class LiveSaveSnackbarComponent implements OnInit {
  public snackbarFadingOut: boolean;
  public readonly SavingState = SavingState;
  public savingState: SavingState = SavingState.IDLE;
  private resetTimeout: ReturnType<typeof setTimeout>;
  private fadeOutTimeout: ReturnType<typeof setTimeout>;

  constructor(
    public readonly liveSaveSnackbarService: LiveSaveSnackbarService,
    public readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.liveSaveSnackbarService.trigger$.subscribe(() => {
      this.reset();
      this.savingState = SavingState.SAVING;
      this.changeDetector.detectChanges();
    });
    this.liveSaveSnackbarService.close$.subscribe((success: boolean) => {
      this.savingState = success ? SavingState.SAVED : SavingState.FAILED;
      this.fadeOutTimeout = setTimeout(() => {
        this.snackbarFadingOut = true;
      }, 2000);
      this.resetTimeout = setTimeout(() => {
        this.reset();
      }, 2800);
    });
  }

  reset(): void {
    this.savingState = SavingState.IDLE;
    this.snackbarFadingOut = false;
    clearTimeout(this.fadeOutTimeout);
    clearTimeout(this.resetTimeout);
  }
}
