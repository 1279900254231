import { CtrStatus } from '@common/enums';

import { Discipline } from './discipline';
import { Product } from './product';

export interface ProductsAndDisciplines {
  products: Product[];
  disciplines: Discipline[];
}

export interface PxDIndex<T = number> {
  productId: T;
  disciplineId: T;
}

export interface PxDCustomData extends PxDIndex {
  /** when set to true forces to display product row and discipline column associated with it */
  forceShowContext?: boolean;
  selectable?: boolean;
  ctrStatus?: CtrStatus;
}

export interface IPxD extends PxDIndex<number> {
  id: number;
  businessSegmentId: number;
  shortName: string;

  owner?: string;
  hasMasterData: boolean;
  hasPrincipals: boolean;
  ctrCount?: number;
  isActive: boolean;
  principals?: {
    fullName: string;
    entityId: number;
    entityName: string;
    globalEntity: boolean;
  }[];
}

export interface PxDPrincipals {
  id: number;
  principals?: {
    fullName: string;
    entityId: number;
    entityName: string;
    globalEntity: boolean;
  }[];
}

export interface IPxDDetailed extends IPxD {
  blocksCount: number;
  scopesCount: number;
  activitiesCount: number;
  requestedCTRsCount: number;
  completedCTRsCount: number;
  description: string;
  users: [
    {
      id: number;
      isPrincipal: boolean;
      isPrincipalIn: number[];
    }
  ];
}

/**
 * Class created from object use in code.
 * Probably there exists interface that comes from server that should be used instead.
 * Check if can be unified with:
 * @see IScenarioCTR
 * @see IScenarioCTRGetScenarioResponsePart
 */
export interface PxDCell {
  ctrStatus: CtrStatus;
  /** @deprecated remove when not used */
  chkStatus?: boolean;
  /** @deprecated remove when not used */
  chkRevision?: boolean;
  revisionNumber?: number;
  disciplineId: number;
  productId: number;
  /** pxdId */
  ctrId: number;
  ctrShortName?: string;
  userId?: number;
  scenarioCTRId: number;
  ctrRequestedDate?: string; //
  isCtrOwnerAcvite?: boolean;
  revisionComment?: string;
  ctrOwner?: string;
  entity?: string;
  actionInProgress?: boolean;
  wasVisitedByCtrOwner: boolean;
}
