import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { EntityMultiSelectorComponent } from './entity-multi-selector.component';

const components = [EntityMultiSelectorComponent];

@NgModule({
    imports: [SharedModule, MaterialModule, ...components],
    exports: components,
})
export class EntityMultiSelectorModule {}
