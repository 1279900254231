import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger, MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { format } from 'date-fns';
import _ from 'lodash';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { IFilterCondition, IFilterConditionWithName } from '@models/filters';

import { DefaultDateFormat } from '@app/DateAdapter';
import { ConditionType } from '../enums';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ChipsComponent),
        },
    ],
    standalone: true,
    imports: [
        MatLegacyFormFieldModule,
        MatChipsModule,
        NgFor,
        NgIf,
        MatIconModule,
        ReactiveFormsModule,
        MatLegacyAutocompleteModule,
        MatDatepickerModule,
        MatLegacyOptionModule,
        AsyncPipe,
    ],
})
export class ChipsComponent implements ControlValueAccessor {
  public optionsSubject$ = new ReplaySubject<IFilterConditionWithName[]>(1);

  @Input() public chipsName: string;

  @Input() public placeholder = '';
  @Input() public removable = true;
  @Input() public datePick;
  @Input() public appearance = 'outline';

  public selectedValues$ = new BehaviorSubject<IFilterConditionWithName[]>([]);
  @ViewChild('chipsInput') chipsInput: ElementRef<HTMLInputElement>;
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  public inputFormControl = new UntypedFormControl();
  public separatorKeysCodes: number[] = [];
  public notSelectedOptions$ = combineLatest([
    this.optionsSubject$,
    this.selectedValues$,
  ]).pipe(
    map(([options = [], selectedValues = []]) =>
      options.filter(
        (option) => !selectedValues.find(({ value }) => option.value === value)
      )
    ),
    tap((options) =>
      this.datePick
        ? options.push({
            name: 'Choose date',
            value: 'choose_date',
            type: ConditionType.CtrDueDate,
          } as IFilterConditionWithName)
        : EMPTY
    )
  );
  public filteredOptions$ = combineLatest([
    this.notSelectedOptions$,
    (this.inputFormControl.valueChanges as Observable<IFilterCondition>).pipe(
      startWith(null)
    ),
  ]).pipe(
    map(([options, value]) =>
      value ? this.filter(options, value) : [...options]
    )
  );
  public disabled: boolean;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  private onChange: (value) => void;
  private onTouched: () => void;

  @Input()
  public set options(options: IFilterConditionWithName[]) {
    this.optionsSubject$.next(options);
  }

  public writeValue(value: any): void {
    this.selectedValues$.next(value === null ? [] : value);
  }

  public registerOnChange(callback: (value: any) => any): void {
    this.onChange = callback;
  }

  public registerOnTouched(callback: () => any): void {
    this.onTouched = callback;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.inputFormControl.disable();
    } else {
      this.inputFormControl.enable();
    }
  }

  public add(condition: IFilterConditionWithName): void {
    if (this.selectedValues$) {
      this.selectedValues$.next(
        _.uniqBy(
          [...this.selectedValues$.value, condition],
          ({ value }) => value
        )
      );
    }

    if (!this.onChange) {
      throw Error(`Invalid formControl binding ${this.chipsName}`);
    }

    this.onTouched();
    this.onChange(this.selectedValues$.value);
    this.inputFormControl.setValue(null);
  }

  public remove(condition: IFilterCondition): void {
    const selectedValues = [...this.selectedValues$.value].filter(
      ({ value }) => value !== condition.value
    );
    this.selectedValues$.next(selectedValues);

    this.onTouched();
    this.onChange(selectedValues);
    this.chipsInput.nativeElement.blur();
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.value === 'choose_date') {
      this.datePicker.open();
    } else {
      this.add(event.option.value);
      this.chipsInput.nativeElement.value = '';
    }
  }

  public openAutocompletePanel() {
    this.autoTrigger.openPanel();
  }

  public check(date: Date) {
    const formattedDate = format(date, DefaultDateFormat);
    const conditionValue = {
      value: formattedDate,
      name: formattedDate,
    } as IFilterConditionWithName;
    this.add(conditionValue);
  }

  private filter(
    options: IFilterConditionWithName[],
    value: string | IFilterCondition
  ): IFilterCondition[] {
    let filterValue: any = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (typeof value.value === 'string') {
      filterValue = value.value.toLowerCase();
    } else {
      filterValue = value.value;
    }

    if (!filterValue) {
      return [];
    }

    return options.filter((option) => {
      let result = false;
      if (typeof option.value === 'string') {
        result = option.value.toLowerCase().includes(filterValue);
      }

      result = result || option.name.toLowerCase().includes(filterValue);

      return result;
    });
  }
}
