import { Component, Input, OnChanges } from '@angular/core';
import {
  CompareScenarioCtrResponse,
  CompareScenarioResponse,
} from '@app/models/backendModel';

import { CtrStatus } from '@common/enums';
import { ScenarioComparisonCellComponent } from '../scenario-comparison-cell/scenario-comparison-cell.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-scenario-ctrs-summary',
    templateUrl: './scenario-ctrs-summary.component.html',
    styleUrls: ['./scenario-ctrs-summary.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ScenarioComparisonCellComponent,
        DatePipe,
    ],
})
export class ScenarioCtrsSummaryComponent implements OnChanges {
  @Input() ctrTypes: string[];
  @Input() firstColumn: boolean;
  @Input() projectId: number;
  @Input() scenario: CompareScenarioResponse;

  public ctrsByType: CompareScenarioCtrResponse[];

  public ngOnChanges(): void {
    this.ctrsByType = this.getScenarioCtrsByType();
  }

  public isCompleted(ctrStatus: CtrStatus): boolean {
    return ctrStatus === CtrStatus.COMPLETED;
  }

  public getTotalGecUtilization(ctr: CompareScenarioCtrResponse): string {
    return ctr.totalGECUtilization.toString();
  }

  private getScenarioCtrsByType(): CompareScenarioCtrResponse[] {
    const ctrs = [];
    this.ctrTypes.forEach((type) => {
      ctrs.push(this.getCtrPerType(type));
    });
    return ctrs;
  }

  private getCtrPerType(type: string): CompareScenarioCtrResponse {
    return (
      this.scenario?.scenarioCTRs?.find((x) => x.ctrShortName === type) || null
    );
  }
}
