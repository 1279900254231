import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, shareReplay, take } from 'rxjs/operators';

export enum SelectionMode {
  NO_SELECTION = 0,
  SINGLE_SELECTION = 1,
  MULTI_SELECTION = 3,
}

export const selectionModeToken = new InjectionToken<SelectionMode>(
  'selectionMode'
);

@Injectable()
export class SelectionService<T> {
  private readonly destroyRef = inject(DestroyRef);

  private readonly selectedCells$ = new ReplaySubject<T[]>(1);

  public selectionChange$ = this.selectedCells$.pipe(
    takeUntilDestroyed(this.destroyRef),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    @Optional() @Inject(selectionModeToken) public selectionMode: SelectionMode
  ) {
    if (this.selectionMode === null) {
      this.selectionMode = SelectionMode.NO_SELECTION;
    }
  }

  public isActive() {
    return this.selectionMode !== SelectionMode.NO_SELECTION;
  }

  public setSelected(value: T[]) {
    this.selectedCells$.next(value);
  }

  public toggle(selection: T) {
    if (!this.isActive()) {
      return false;
    }

    this.selectedCells$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe((selectedCells) => {
        const hash = JSON.stringify(selection);
        const index = selectedCells.findIndex(
          (item) => JSON.stringify(item) === hash
        );

        if (index === -1) {
          if (this.selectionMode === SelectionMode.MULTI_SELECTION) {
            selectedCells = [...selectedCells, selection];
          } else {
            selectedCells = [selection];
          }
        } else {
          selectedCells = [...selectedCells];
          selectedCells.splice(index, 1);
        }

        this.selectedCells$.next(selectedCells);
      });

    return true;
  }
}
