import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-enter-edit-mode-dialog',
    templateUrl: './enter-edit-mode-dialog.component.html',
    styleUrls: ['./enter-edit-mode-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class EnterEditModeDialogComponent {}
