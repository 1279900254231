// tslint:disable:ordered-imports
import '@angular/compiler';
import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FEATURE_KEY as USERS_FEATURE_KEY } from '@collections/users/store/users.reducer';
import { provideRouterStore } from '@ngrx/router-store';
import { UnsavedChangesGuard } from '@scenario/unsaved-changes.guard';
import { SharedModule } from '@shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { ActivitiesChangeLogModule } from './app/admin/activities-change-log/activities-change-log.module';
import { AdminActivitiesModule } from './app/admin/admin-activities/admin-activities.module';
import { AppConfigService } from './app/app-config.service';
import { AppRoutingModule } from './app/app-routing.module';
import { AppService } from './app/app-service.service';
import { AppComponent } from './app/app.component';
import { initializeApp } from './app/app.module';
import { AuthModule } from './app/auth/auth.module';
import { ConfirmDialogModule } from './app/common/confirm-dialog/confirm-dialog.module';
import { CoreModule } from './app/core/core.module';
import { EventLogModule } from './app/event-log/event-log.module';
import { HeaderModule } from './app/layout/header/header.module';
import { SideNavModule } from './app/layout/sidenav/sidenav.module';
import { MediatorService } from './app/mediator.service';
import { ProjectModule } from './app/project/project.module';
import { ErrorPopupModule } from './app/scenario/ctr/error-popup/error-popup.module';
import { SdkCallService } from './app/sdk-call.service';
import { environment } from './environments/environment';

const restoredUser: { currentUserId: number } = JSON.parse(
  localStorage.getItem(USERS_FEATURE_KEY)
) || {
  currentUserId: -1,
};

const googleAnalyticModules =
  environment.gTag.Key !== ''
    ? [
        NgxGoogleAnalyticsModule.forRoot(environment.gTag.Key, [
          {
            command: 'config',
            values: [
              environment.gTag.Key,
              { debug_mode: environment.gTag.EnableDebugMode },
              { user_id: restoredUser.currentUserId },
            ],
          },
        ]),
        NgxGoogleAnalyticsRouterModule,
      ]
    : [];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ActivitiesChangeLogModule,
      AngularSvgIconModule.forRoot(),
      AppRoutingModule,
      AdminActivitiesModule, // this needs to be after AppRoutingModule
      AuthModule.forRoot(),
      BrowserModule,
      ConfirmDialogModule,
      CoreModule,
      ErrorPopupModule,
      HeaderModule,
      ProjectModule,
      SharedModule,
      SideNavModule,
      EventLogModule,
      ...googleAnalyticModules
    ),
    { provide: HTTP_INTERCEPTORS, useExisting: AppService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    SdkCallService,
    UnsavedChangesGuard,
    AppConfigService,
    MediatorService,
    provideRouterStore(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
