import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ConfirmDialogComponent } from './confirm-dialog.component';

const components = [ConfirmDialogComponent];

@NgModule({
    imports: [SharedModule, ...components],
    exports: components,
})
export class ConfirmDialogModule {}
