import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AsyncPipe, NgIf } from '@angular/common';
import { getScenarioAction } from '@app/collections/scenarios/store/scenarios.actions';
import { selectSidenavProjectConfiguration } from '@app/layout/sidenav/store/sidenav.selectors';
import { selectProjectByIdFactory$ } from '@collections/projects/store/projects.selectors';
import { selectScenarioByScenarioIdFactory } from '@collections/scenarios/store/scenarios.selectors';
import { selectCurrentRouteUserRole } from '@core/store/core.selectors';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class BreadcrumbsComponent {
  constructor(private readonly store: Store) {}
  private readonly scenarioId$ = new BehaviorSubject<number>(0);

  @Input() set projectId(_projectId: number | string) {
    if (!_projectId) {
      this.projectName$ = null;
    } else {
      let intProjectId = 0;
      if (typeof _projectId === 'string') {
        intProjectId = parseInt(_projectId, 10);
      } else {
        intProjectId = _projectId;
      }
      this.projectName$ = this.store.select(selectCurrentRouteUserRole).pipe(
        filter((v) => !!v),
        take(1),
        switchMap((userRole) =>
          selectProjectByIdFactory$(this.store, intProjectId, userRole)
        ),
        map(({ projectName = null }) => projectName)
      );
    }
  }
  @Input() set scenarioId(_scenarioId: number | string) {
    if (!_scenarioId) {
      this.scenarioName$ = null;
    } else {
      let intScenarioId = 0;
      if (typeof _scenarioId === 'string') {
        intScenarioId = parseInt(_scenarioId, 10);
      } else {
        intScenarioId = _scenarioId;
      }
      this.scenarioId$.next(intScenarioId);
      this.scenarioName$ = this.store
        .select(selectScenarioByScenarioIdFactory(intScenarioId))
        .pipe(
          filter((v) => !!v),
          take(1),
          map(({ scenarioName = null }) => scenarioName)
        );
      this.store.dispatch(
        getScenarioAction({ payload: { scenarioId: intScenarioId } })
      );
    }
  }

  @Input() set ctrId(_ctrId: number | string) {
    if (!_ctrId) {
      this.ctrName$ = null;
    } else {
      let intCtrId = 0;
      if (typeof _ctrId === 'string') {
        intCtrId = parseInt(_ctrId, 10);
      } else {
        intCtrId = _ctrId;
      }
      this.ctrName$ = combineLatest([
        this.store.select(selectSidenavProjectConfiguration),
        this.scenarioId$,
      ]).pipe(
        filter(
          ([projectMetaData, scenarioId]) => !!projectMetaData && !!scenarioId
        ),
        take(1),
        map(
          ([projectMetaData, scenarioId]) =>
            projectMetaData?.scenarios
              ?.find((scenario) => scenario.scenarioId === scenarioId)
              ?.sctrs.find((ctr) => ctr.scenarioCTRId === intCtrId)
              ?.ctrShortName
        )
      );
    }
  }

  public projectName$: Observable<string>;

  public scenarioName$: Observable<string>;

  public ctrName$: Observable<string>;
}
