import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideZero',
  standalone: true,
})
export class HideZeroPipe implements PipeTransform {
  /**
   * @param value the value that needs to be displayed if controlValue is zero it will be discarded
   * @param controlValue this value controls if first value is displayed or discarded in case if its null first parameter is taking over this role
   * @returns value or empty string if controlValue is zero
   *
   * @example
   * transform(0, null) // ''
   * transform(0, 0) // ''
   * transform(0, 5) // 0
   * transform(1, null) // 1
   * transform(1, 0) // ''
   * transform(1, 5) // 1
   */
  transform(value: string | number, controlValue: number = null): string {
    if (value == null) {
      return '';
    }
    if (controlValue === null) {
      return value == 0 ? '' : value.toString();
    }
    return controlValue == 0 ? '' : value.toString();
  }
}
