import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MdmProjectInfoModule } from '@app/mdm-project-info/mdm-project-info.module';
import { SharedModule } from '@shared/shared.module';

import { CalendarizationStateModule } from '@app/calendarization/store/calendarization-state.module';
import { DistributeWorkloadEvenlyDialogComponent } from '../calendarization/distribute-workload-evenly-dialog/distribute-workload-evenly-dialog.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditOwnerDialogComponent } from './edit-owner-dialog/edit-owner-dialog.component';
import { ProjectCalendarizationComponent } from './project-calendarization/project-calendarization.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectscenriosComponent } from './project-summary/project-scenarios.component';
import { ProjectSummaryBaseComponent } from './project-summary/project-summary-base.component';
import { ProjectComponent } from './project/project.component';
import { ScenarioComparisonModule } from './scenario-comparison/scenario-comparison.module';
import { ScenarioDefinitionComponent } from './scenario-definition/scenario-definition.component';

@NgModule({
  imports: [
    FormsModule,
    ProjectRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    ScenarioComparisonModule,
    MdmProjectInfoModule,
    CalendarizationStateModule,
    ProjectCalendarizationComponent,
    DistributeWorkloadEvenlyDialogComponent,
    CreateProjectComponent,
    ProjectscenriosComponent,
    ProjectSummaryBaseComponent,
    ScenarioDefinitionComponent,
    ProjectComponent,
    EditOwnerDialogComponent,
  ],
})
export class ProjectModule {}
