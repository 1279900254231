import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ChangeLogDialogComponent } from './change-log-dialog/change-log-dialog.component';
import { ChangeLogTableComponent } from './change-log-table/change-log-table.component';
import { EnterEditModeDialogComponent } from './enter-edit-mode-dialog/enter-edit-mode-dialog.component';

@NgModule({
    imports: [SharedModule, ChangeLogDialogComponent,
        ChangeLogTableComponent,
        EnterEditModeDialogComponent],
    exports: [ChangeLogDialogComponent],
})
export class ActivitiesChangeLogModule {}
