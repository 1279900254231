import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort, MatSortModule } from '@angular/material/sort';

import { AdminUserResponsibility } from '@models/userDetails';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-user-responsibilities',
    templateUrl: './user-responsibilities.component.html',
    styleUrls: ['./user-responsibilities.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatSortModule,
        NgFor,
        MatButtonModule,
        MatIconModule,
    ],
})
export class UserResponsibilitiesComponent {
  public sortedResponsibilities: AdminUserResponsibility[];

  @Input() set assignedPxDs(responsibilities: AdminUserResponsibility[]) {
    this.sortedResponsibilities = responsibilities
      ?.slice()
      .sort(
        (a, b) =>
          this.compare(a.entity, b.entity, true) ||
          this.compare(a.pxdName, b.pxdName, true) ||
          this.compareBoolean(a.isPrincipal, b.isPrincipal, true)
      );
  }

  @Output() onUnassignResponsibility = new EventEmitter<{
    pxdId: number;
    entityId: number;
  }>();

  public sortData(sort: Sort) {
    this.sortedResponsibilities = this.sortedResponsibilities.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'entity':
          return this.compare(a.entity, b.entity, isAsc);
        case 'pxdName':
          return this.compare(a.pxdName, b.pxdName, isAsc);
        case 'isPrincipal':
          return this.compareBoolean(a.isPrincipal, b.isPrincipal, isAsc);
      }
    });
  }

  private compare(a: string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private compareBoolean(a: boolean, b: boolean, isAsc: boolean) {
    return (a && a !== b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public unassignResponsibility(pxdId: number, entityId: number) {
    this.onUnassignResponsibility.emit({ pxdId, entityId });
  }

  public trackByResponsibility(
    index: number,
    responsibility: AdminUserResponsibility
  ) {
    return `${responsibility.pxdId}-${responsibility.entityId}`;
  }
}
