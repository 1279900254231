import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { IGetMasterDataLock } from '@models/master-data-lock';

export enum LockOverrideAction {
  TAKE = 'take',
  DISCARD = 'discard',
  APPLY = 'apply',
}

const fixLockExpirationDate = (expirationDate) => expirationDate + 'Z';

@Injectable({
  providedIn: 'root',
})
export class LockApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getLock() {
    return this.httpClient
      .get<IGetMasterDataLock>('{apiUrl}masterData/lock')
      .pipe(
        map((lock) =>
          lock
            ? {
                ...lock,
                expirationDate: fixLockExpirationDate(lock.expirationDate),
              }
            : null
        )
      );
  }

  public extendLock() {
    return this.httpClient.put('{apiUrl}masterData/lock', {});
  }

  public takeLock(params: { overrideAction?: LockOverrideAction } = {}) {
    return this.httpClient.post(`{apiUrl}masterData/lock`, {}, { params });
  }

  public discardChanges() {
    return this.httpClient.delete('{apiUrl}masterData/lock');
  }

  public commitChanges(notifyUsers) {
    return this.httpClient.post(
      `{apiUrl}masterData/commit?notifyUsers=${notifyUsers ? 'true' : 'false'}`,
      {}
    );
  }
}
