import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay, filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EditUserFormModule } from '@app/admin/users/edit-user-form/edit-user-form.module';
import { SharedModule } from '@app/shared/shared.module';
import { TutorialModalComponent } from '@app/welcome-tutorial/tutorial-modal/tutorial-modal.component';
import { WelcomeTutorialModule } from '@app/welcome-tutorial/welcome-tutorial.module';
import { getBusinessSegmentsAction } from '@collections/business-segments/store/business-segments.actions';
import { getEntitiesAction } from '@collections/entities/store/entities.actions';
import {
  createUserPublicAction,
  identifyCurrentUserSuccessAction,
  UsersActions,
} from '@collections/users/store/users.actions';
import {
  selectCurrentUser,
  selectMsalUser,
  selectUserByEmailFactory,
  selectUserCreated,
} from '@collections/users/store/users.selectors';
import { EnvironmentService } from '@environments/environment-service';
import { UserDetails } from '@models/userDetails';
import { Observable, ReplaySubject } from 'rxjs';
import { TutorialDialogData } from '../welcome-tutorial/tutorial-modal/tutorial-modal.component';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  standalone: true,
  imports: [SharedModule, EditUserFormModule, WelcomeTutorialModule],
  providers: [EnvironmentService],
})
export class WelcomePageComponent implements OnInit {
  private readonly userAccountCreated$ = new ReplaySubject<boolean>(1);
  private readonly destroyRef = inject(DestroyRef);

  public isTestMode = this.environmentService.isTestMode;
  public delay = 1000;
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly environmentService: EnvironmentService
  ) {}

  public userData: UserDetails;

  public isValid = false;

  public showForm = false;

  public updateState(isValid: boolean): void {
    this.isValid = isValid;
  }

  public reloadAllUsers(): void {
    if (this.isTestMode) {
      this.store.dispatch(UsersActions.forceGetActiveUsersQuery());
    } else {
      this.store.dispatch(UsersActions.getActiveUsersQuery());
    }
  }

  public createAccount(): void {
    this.userAccountCreated$.next(true);
    this.store.dispatch(
      createUserPublicAction({
        context: 'UsersComponent::createAccount',
        payload: { userData: this.userData },
      })
    );

    this.store
      .select(selectUserCreated)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((v) => v),
        delay(this.delay),
        switchMap(() => {
          this.reloadAllUsers();
          return this.store.select(
            selectUserByEmailFactory(this.userData.email)
          );
        }),
        filter((v) => !!v),
        take(1),
        switchMap((user) => {
          this.store.dispatch(
            identifyCurrentUserSuccessAction({
              context: 'UsersComponent::createAccount',
              payload: { user, ready: true },
            })
          );

          return this.openTutorial();
        })
      )
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  public openTutorial(): Observable<void> {
    return this.dialog
      .open<TutorialModalComponent, TutorialDialogData, void>(
        TutorialModalComponent,
        {
          width: 'calc(100vw - 40px)',
          data: {
            isRequestor: this.userData?.isRequestor,
            isEngineer: this.userData?.isEngineer,
          },
          autoFocus: false,
          maxWidth: '100vw',
        }
      )
      .afterClosed();
  }

  public ngOnInit(): void {
    this.store
      .select(selectCurrentUser)
      .pipe(
        takeUntil(this.userAccountCreated$),
        takeUntilDestroyed(this.destroyRef),
        filter((v) => !!v && !this.isTestMode)
      )
      .subscribe(() => this.router.navigate(['/']));
    this.store
      .select(selectMsalUser)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((v) => !!v)
      )
      .subscribe(({ firstName, lastName, email }) => {
        this.userData = {
          userId: null,
          businessSegmentId: 1,
          pxds: [],
          email,
          entityId: null,
          firstName,
          isActive: true,
          isRequestor: false,
          isEngineer: false,
          isAdmin: false,
          lastName,
          userCanBeMadeInActive: false,
        };
      });

    if (this.isTestMode) {
      this.userData = {
        userId: null,
        businessSegmentId: 1,
        pxds: [],
        email: '',
        entityId: null,
        firstName: '',
        isActive: true,
        isRequestor: false,
        isEngineer: false,
        isAdmin: false,
        lastName: '',
        userCanBeMadeInActive: false,
      };
    }
    this.store.dispatch(
      getEntitiesAction({
        context: 'WelcomePageComponent::init',
      })
    );

    this.store.dispatch(
      getBusinessSegmentsAction({
        context: 'WelcomePageComponent::init',
      })
    );
  }
}
