import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrderDialogModule } from '@common/order-dialog/order-dialog.module';
import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';



import { AdminMasterdataRoutingModule } from './admin-masterdata-routing.module';
import { EditActivityDialogComponent } from './edit-activity-dialog/edit-activity-dialog.component';
import { EditActivityFormComponent } from './edit-activity-form/edit-activity-form.component';
import { EditBlockDialogComponent } from './edit-block-dialog/edit-block-dialog.component';
import { EditBlockFormComponent } from './edit-block-form/edit-block-form.component';
import { EditScopeDialogComponent } from './edit-scope-dialog/edit-scope-dialog.component';
import { EditScopeFormComponent } from './edit-scope-form/edit-scope-form.component';
import { MasterdataActivityComponent } from './masterdata-activity/masterdata-activity.component';
import { MasterdataBlockComponent } from './masterdata-block/masterdata-block.component';
import { MasterdataPageComponent } from './masterdata-page/masterdata-page.component';
import { MasterdataScopeComponent } from './masterdata-scope/masterdata-scope.component';
import { MasterdataTableComponent } from './masterdata-table/masterdata-table.component';
import { AdminActivitiesEffects } from './store/admin-activities.effects';
import { FEATURE_KEY, reducer } from './store/admin-activities.reducer';

const components = [
  EditActivityDialogComponent,
  EditActivityFormComponent,
  EditScopeDialogComponent,
  EditScopeFormComponent,
  EditBlockDialogComponent,
  EditBlockFormComponent,
  MasterdataActivityComponent,
  MasterdataBlockComponent,
  MasterdataPageComponent,
  MasterdataScopeComponent,
  MasterdataTableComponent,
];

@NgModule({
    imports: [
    AdminMasterdataRoutingModule,
    SharedModule,
    MaterialModule,
    OrderDialogModule,
    StoreModule.forFeature(FEATURE_KEY, reducer),
    EffectsModule.forFeature([AdminActivitiesEffects]),
    ...components,
],
    exports: components,
})
export class AdminActivitiesModule {}
