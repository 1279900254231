import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { OrderDialogComponent } from './order-dialog.component';

const components = [OrderDialogComponent];

@NgModule({
    imports: [SharedModule, ...components],
    exports: components,
})
export class OrderDialogModule {}
