import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-admin-edit-mode-info-dialog',
    templateUrl: './admin-edit-mode-info-dialog.component.html',
    styleUrls: ['./admin-edit-mode-info-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class AdminEditModeInfoDialogComponent {}
