import { NgModule } from '@angular/core';

import { CharacterCounterComponent } from './character-counter.component';

const directives = [CharacterCounterComponent];

@NgModule({
    imports: [...directives],
    exports: directives,
})
export class CharacterCounterModule {}
