import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MediatorService } from '@app/mediator.service';
import { IMediatorRequest } from '@app/models/backendModel';
import { LiveSaveSnackbarService } from './snackbar/live-save-snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class LiveSavingInterceptorService {
  constructor(
    private readonly http: HttpClient,
    private readonly mediator: MediatorService,
    private readonly liveSaveSnackbarService: LiveSaveSnackbarService
  ) {}

  send<TRequest extends IMediatorRequest, TResponse = void>(
    requestName: string,
    requestBody: TRequest
  ): Observable<TResponse> {
    return this.handleLiveSaveSnackbar(
      this.mediator.send<TRequest, TResponse>(requestName, requestBody)
    );
  }
  public put<T>(url: string, body: any): Observable<T> {
    return this.handleLiveSaveSnackbar(this.http.put<T>(url, body));
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.handleLiveSaveSnackbar(this.http.post<T>(url, body)).pipe(
      map((response) => response)
    );
  }

  public delete<T>(url: string): Observable<T> {
    return this.handleLiveSaveSnackbar(this.http.delete<T>(url));
  }

  public handleLiveSaveSnackbar<T>(call: Observable<T>): Observable<T> {
    this.liveSaveSnackbarService.open();
    return call.pipe(
      tap({
        next: () => this.liveSaveSnackbarService.close(true),
        error: () => this.liveSaveSnackbarService.close(false),
      })
    );
  }
}
