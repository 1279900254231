import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { MdmProjectInfoComponent } from './mdm-project-info/mdm-project-info.component';

const components = [
  MdmProjectInfoComponent,
]

@NgModule({
    imports: [
        SharedModule,
        MaterialModule,
        ...components
    ],
    exports: components
})
export class MdmProjectInfoModule { }
