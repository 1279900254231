import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';

import { Subscription, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ContentScrollService } from '@common/services/content-scroll.service';

import { environment } from '../environments/environment';

import { AsyncPipe, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppService } from './app-service.service';
import { LiveSaveSnackbarComponent } from './common/snackbar/live-save-snackbar.component';
import { LayoutService } from './core/layout/layout.service';
import {
  selectEventLogVisible,
  selectSidenavVisible,
} from './core/store/core.selectors';
import { EventLogComponent } from './event-log/event-log.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    HeaderComponent,
    MatSidenavModule,
    SidenavComponent,
    MatIconModule,
    RouterOutlet,
    EventLogComponent,
    MatProgressSpinnerModule,
    LiveSaveSnackbarComponent,
    AsyncPipe,
  ],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  public environmentColor = environment.color || null;

  public showSpinner$ = combineLatest([
    this.appService.spinnerDisplay,
    this.layoutService.isInterfaceLocked$,
  ]).pipe(
    // fast fix for component update in no update phase
    // caused by some calls to this.appService.spinnerDisplay.next
    // in unknown place
    debounceTime(500),
    map(
      ([spinnerDisplay = false, isInterfaceLocked = false]) =>
        spinnerDisplay || isInterfaceLocked
    )
  );

  private readonly subscription: Subscription = new Subscription();

  public sidenavVisible$ = this.store.select(selectSidenavVisible);

  public activityLogVisible$ = this.store.select(selectEventLogVisible);

  public activitiesOpened = false;

  public isActivityLogFeatureEnabled = environment.isActivityLogFeatureEnabled;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly appService: AppService,
    private readonly router: Router,
    private readonly contentScrollService: ContentScrollService,
    public readonly layoutService: LayoutService,
    private readonly store: Store
  ) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      document.documentElement.scrollTop = 0;
    });
  }

  public toggleSidenav() {
    this.layoutService.sidenavToggle$.next(
      !this.layoutService.sidenavToggle$.value
    );
  }

  public scrollHandler(scrollEvent: Event) {
    const contentScrollPosition = (scrollEvent.target as HTMLElement).scrollTop;
    this.contentScrollService.updateContentOffsetTop(contentScrollPosition);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
