import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { CtrStatus } from '@common/enums';
import { IPxD } from '@models/pxd';

@Component({
    selector: 'app-info-pxd-grid-cell',
    template: '<ng-content></ng-content>',
    styleUrls: ['./info-pxd-grid-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class InfoPxdGridCellComponent implements OnChanges {
  public readonly CtrStatus = CtrStatus;

  @Input() public pxd: IPxD = null;

  @Input()
  @HostBinding('class.ctr-status-selected')
  public selected = false;

  @Input()
  @HostBinding('class.selectable')
  public selectable = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.pxd && this.pxd) {
      this.selectable = this.pxd.hasMasterData;
    }
  }
}
