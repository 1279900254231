import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs.component';

const components = [
  BreadcrumbsComponent
]

@NgModule({
    exports: components,
    imports: [
        CommonModule,
        ...components
    ]
})
export class BreadcrumbsModule { }
