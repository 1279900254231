import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { AdminEditModeInfoDialogComponent } from './admin-edit-mode-info-dialog/admin-edit-mode-info-dialog.component';
import { LockIndicatorComponent } from './lock-indicator.component';

const components = [LockIndicatorComponent, AdminEditModeInfoDialogComponent];

@NgModule({
    imports: [CommonModule, MaterialModule, ...components],
    exports: components,
})
export class LockIndicatorModule {}
