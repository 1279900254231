import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CompareScenarioResponse } from '@app/models/backendModel';
import { ScenarioCtrsSummaryComponent } from '../scenario-ctrs-summary/scenario-ctrs-summary.component';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { ScenarioStatusIconComponent } from '../../../common/scenario-status-icon/scenario-status-icon.component';
import { NgIf, NgFor } from '@angular/common';

// tslint:disable:use-component-view-encapsulation
@Component({
    selector: 'app-scenario-comparison-column',
    templateUrl: './scenario-comparison-column.component.html',
    styleUrls: ['./scenario-comparison-column.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        ScenarioStatusIconComponent,
        MatIconModule,
        MatLegacyFormFieldModule,
        MatLegacySelectModule,
        NgFor,
        MatLegacyOptionModule,
        ScenarioCtrsSummaryComponent,
    ],
})
export class ScenarioComparisonColumnComponent implements OnInit {
  @Input() projectId: number;
  @Input() scenario: CompareScenarioResponse;
  @Input() ctrTypes: string[];
  @Input() allProjectScenarios: CompareScenarioResponse[];

  public selectedScenario: CompareScenarioResponse;

  public ngOnInit(): void {
    this.selectedScenario = this.scenario;
  }
}
