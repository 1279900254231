import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ScenarioStatusIconComponent } from '@app/common/scenario-status-icon/scenario-status-icon.component';
import { LiveSaveSnackbarService } from '@app/common/snackbar/live-save-snackbar.service';
import { DefaultDateFormat } from '@app/DateAdapter';
import { CardModule } from '@common/card/card.module';
import { ChipsModule } from '@common/chips-component/chips.module';
import { CtrStatusIconModule } from '@common/ctr-status-icon/ctr-status-icon.module';
import { CharacterCounterModule } from '@common/directives/character-cunter/character-counter.module';
import { NumbersInputGuardModule } from '@common/directives/numbers-input-guard.module';
import { DocumentExportDialogModule } from '@common/document-export-dialog/document-export-dialog.module';
import { LockIndicatorModule } from '@common/lock-indicator/lock-indicator.module';
import { CommonPipesModule } from '@common/pipe/common-pipes.module';
import { StatusLabelModule } from '@common/status-label/status-label.module';
import { TableModule } from '@common/table/table.module';
import { UserPickerModule } from '@common/user-picker/user-picker.module';
import { MaterialModule } from './material.module';

@NgModule({
  exports: [
    AngularSvgIconModule,
    CardModule,
    CharacterCounterModule,
    ChipsModule,
    CommonModule,
    CommonPipesModule,
    CtrStatusIconModule,
    DocumentExportDialogModule,
    DragDropModule,
    FormsModule,
    LockIndicatorModule,
    MaterialModule,
    NumbersInputGuardModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    UserPickerModule,
    StatusLabelModule,
    ScenarioStatusIconComponent,
  ],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: DefaultDateFormat },
    },
    LiveSaveSnackbarService,
  ],
  imports: [ScenarioStatusIconComponent],
})
export class SharedModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'loupe',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/loupe.svg')
    );
    iconRegistry.addSvgIcon(
      'empty-state',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/empty_state.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'expand',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/expand.svg')
    );
    iconRegistry.addSvgIcon(
      'exchange',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/exchange.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'calendar-month',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/calendar_month.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'xls-icon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/xls-icon.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'xls-client-icon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/xls-client-icon.svg'
      )
    );
  }
}
