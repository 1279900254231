import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MaterialModule } from '@shared/material.module';

import { ChipsComponent } from './chips.component';

const components = [ChipsComponent];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        ...components,
    ],
    exports: components,
})
export class ChipsModule {}
