import { Component, HostBinding, Input } from '@angular/core';
import { ScenarioStatus } from '@common/enums';

@Component({
    selector: 'app-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
    standalone: true
})
export class StatusLabelComponent {
  @Input() status: ScenarioStatus;

  @HostBinding('class')
  get classes(): Record<string, boolean> {
    return {
      'not-started': this.status === ScenarioStatus.NOT_STARTED,
      'in-progress': this.status === ScenarioStatus.IN_PROGRESS,
      'completed': this.status === ScenarioStatus.COMPLETED,
      'cancelled': this.status === ScenarioStatus.CANCELLED,
      'on-hold': this.status === ScenarioStatus.ON_HOLD
    }
  }
}
