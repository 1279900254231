@if(projectName$ | async; as projectName) {
<div data-test="breadcrumb-project-label">
  <span class="label">Project:</span>
  <span class="value">{{ projectName }}</span>
</div>
} @if(scenarioName$ | async; as scenarioName) {

<div data-test="breadcrumb-scenario-label">
  <span class="label">Scenario:</span>
  <span class="value">{{ scenarioName }}</span>
</div>
} @if(ctrName$ | async; as ctrName) {
<div data-test="breadcrumb-ctr-label">
  <span class="label">CTR:</span> <span class="value">{{ ctrName }}</span>
</div>
}
