import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger, MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { IGetUsersResponse } from '@models/users';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'app-user-picker',
    templateUrl: './user-picker.component.html',
    standalone: true,
    imports: [
        MatLegacyFormFieldModule,
        MatTooltipModule,
        MatLegacyInputModule,
        MatLegacyAutocompleteModule,
        ReactiveFormsModule,
        MatIconModule,
        NgFor,
        MatLegacyOptionModule,
        NgIf,
        AsyncPipe,
    ],
})
export class UserPickerComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() userOptions: IGetUsersResponse[];
  @Input() showEntity: boolean;

  public showDropdown;
  public initialUsers;
  public showTooltip;
  public filteredUsers$: Observable<IGetUsersResponse[]>;

  ngOnInit(): void {
    this.initialUsers = this.userOptions
      ?.filter((user) => user.userId !== this.control.value.userId)
      .sort((userA, userB) =>
        userA.fullName.toLowerCase().localeCompare(userB.fullName.toLowerCase())
      );
    if (this.initialUsers && this.initialUsers.length === 0) {
      this.control.disable();
      this.showTooltip = true;
    }

    this.filteredUsers$ = this.control.valueChanges.pipe(
      startWith([]),
      map((value: string | IGetUsersResponse) => {
        this.showDropdown = true;
        if (!value) {
          return this.initialUsers;
        }
        if (typeof value === 'object') {
          return this.initialUsers;
        }
        const filterValue = value?.toLowerCase();
        return this.initialUsers.filter((user) =>
          user.fullName.toLocaleLowerCase().includes(filterValue)
        );
      })
    );
  }

  public inputClick(event: Event, trigger: MatAutocompleteTrigger) {
    this.showDropdown = true;
    event.stopPropagation();
    trigger.openPanel();
    (event.target as HTMLInputElement).select();
  }

  public userToString(user) {
    return this.showEntity
      ? `${user?.fullName} (${user?.entityName})`
      : `${user?.fullName}`;
  }
}
