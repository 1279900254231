import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { UserResponsibilitiesComponent } from './user-responsibilities.component';

const components = [UserResponsibilitiesComponent];

@NgModule({
    imports: [SharedModule, MaterialModule, ...components],
    exports: components,
})
export class UserResponsibilitiesModule {}
