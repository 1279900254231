import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SdkCallService } from '@app/sdk-call.service';
import { IProjectScenario } from '@models/project-scenario';
import { filter, map, Observable, switchMap } from 'rxjs';
import { ScenarioComparisonComponent } from '../scenario-comparison/scenario-comparison.component';
import { MatCardModule } from '@angular/material/card';
import { ScenarioDefinitionComponent } from '../scenario-definition/scenario-definition.component';
import { ProjectscenriosComponent } from './project-scenarios.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-project-summary-base',
    templateUrl: './project-summary.component.html',
    styleUrls: ['./project-scenarios.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatExpansionModule,
        ProjectscenriosComponent,
        ScenarioDefinitionComponent,
        MatCardModule,
        ScenarioComparisonComponent,
        AsyncPipe,
    ],
})
export class ProjectSummaryBaseComponent implements OnInit {
  projectId$: Observable<number>;
  projectScenarioData$: Observable<IProjectScenario[]>;
  @Input() isProjectDisabled: boolean;
  @Input() defaultCurrency: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly sdkService: SdkCallService
  ) {}

  ngOnInit(): void {
    this.projectId$ = this.activatedRoute.params.pipe(
      filter((x) => x.projectId),
      map((x) => x.projectId)
    );
    this.projectScenarioData$ = this.projectId$.pipe(
      switchMap((projectId) =>
        this.sdkService.baseCaseChanged.pipe(map(() => projectId))
      ),
      switchMap((projectId) =>
        this.sdkService.getprojectScenariosData(projectId)
      ),
      map((projectScenarioData) => {
        projectScenarioData.sort((a, b) => a.number - b.number);
        return projectScenarioData;
      })
    );
  }
}
