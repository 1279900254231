import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: true,
    imports: [RouterLink],
})
export class ContactComponent {
  public eCTRSupportPlatform =
    'https://teams.microsoft.com/l/channel/19%3Af55ec4ef5c8441909940aa596462aff2%40thread.tacv2/General?groupId=7992f545-d8ce-4492-bbf9-ded3775b1d38&tenantId=0804c951-93a0-405d-80e4-fa87c7551d6a';

  public feedbackTrackerUrl =
    'https://teams.microsoft.com/l/entity/26bc2873-6023-480c-a11b-76b66605ce8c/_djb2_msteams_prefix_3431808697?context={"subEntityId"%3Anull%2C"channelId"%3A"19%3Af55ec4ef5c8441909940aa596462aff2%40thread.tacv2"}&groupId=7992f545-d8ce-4492-bbf9-ded3775b1d38&tenantId=0804c951-93a0-405d-80e4-fa87c7551d6a';
}
