import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '@shared/shared.module';

import { SidenavComponent } from './sidenav.component';
import { SidenavEffects } from './store/sidenav.effects';
import { FEATURE_KEY, reducer } from './store/sidenav.reducer';

const components = [SidenavComponent];

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(FEATURE_KEY, reducer),
        EffectsModule.forFeature([SidenavEffects]),
        ...components,
    ],
    exports: components,
})
export class SideNavModule {}
