import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { CtrStatus } from '@common/enums';
import { IPxD, PxDCell } from '@models/pxd';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-requestor-pxd-grid-cell',
    templateUrl: './requestor-pxd-grid-cell.component.html',
    styleUrls: ['./requestor-pxd-grid-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class RequestorPxdGridCellComponent implements OnChanges {
  public readonly CtrStatus = CtrStatus;

  @Input() public pxd: IPxD = null;

  @Input() public scenarioCTR: PxDCell = null;

  @Input() public showCtrOwners = false;

  @Input() public currentUserFullName = null;

  @Input()
  @HostBinding('class.selectable')
  public interactive = false;

  @Input()
  public cancelable = false;

  @HostBinding('class.pxd-active')
  public active = false;

  @HostBinding('class.pxd-disabled')
  public get disabled() {
    return !this.active;
  }

  @HostBinding('class.pxd-with-master-data')
  public withMasterdata = false;

  @HostBinding('class.pxd-without-master-data')
  public withoutMasterdata = false;

  @HostBinding('class.pxd-with-principals')
  public withPrincipals = false;

  @HostBinding('class.pxd-without-principals')
  public withoutPrincipals = false;

  @HostBinding('class')
  public ctrStatus = '';

  @Output()
  public onCTROwnerEdit = new EventEmitter<PxDCell>();

  @Output()
  public onCTRORequestRevision = new EventEmitter<PxDCell>();

  @Output()
  public onCTRCancel = new EventEmitter<PxDCell>();

  @Output()
  public onCTRRecover = new EventEmitter<PxDCell>();

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.pxd && this.pxd) {
      this.withMasterdata = this.pxd.hasMasterData;
      this.withoutMasterdata = !this.withMasterdata;
      this.withPrincipals = this.pxd.hasPrincipals;
      this.withoutPrincipals = !this.withPrincipals;
      this.active = this.pxd.isActive;
    }

    if (changes.scenarioCTR && !!this.scenarioCTR) {
      this.ctrStatus = this.scenarioCTR
        ? {
          [ CtrStatus.SELECTED ]: 'ctr-status ctr-status-selected',
          [ CtrStatus.NOT_STARTED ]: 'ctr-status ctr-status-not-started',
          [ CtrStatus.IN_PROGRESS ]: 'ctr-status ctr-status-in-progress',
          [ CtrStatus.COMPLETED ]: 'ctr-status ctr-status-completed',
          [ CtrStatus.CANCELLED ]: 'ctr-status ctr-status-cancelled',
        }[ this.scenarioCTR.ctrStatus ]
        : '';
    }
  }

  public openCTROwnerEdit() {
    this.onCTROwnerEdit.emit(this.scenarioCTR);
  }

  public cancelCTR() {
    this.onCTRCancel.emit(this.scenarioCTR);
  }

  public recoverCTR() {
    this.onCTRRecover.emit(this.scenarioCTR);
  }

  public requestRevision() {
    this.onCTRORequestRevision.emit(this.scenarioCTR);
  }
}
