import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';

export interface TutorialDialogData {
  isRequestor: boolean;
  isEngineer: boolean;
}

const requestorUrl = 'https://digicorner.sharepoint.com/sites/ectrvideogroup/_layouts/15/embed.aspx?UniqueId=c20e1056-3e59-4bd5-a203-1e78721351d0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create';

const engineerUrl = 'https://digicorner.sharepoint.com/sites/ectrvideogroup/_layouts/15/embed.aspx?UniqueId=d55a5d46-6212-4f75-99d2-a2d52996e997&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create';

const bothUrl = 'https://digicorner.sharepoint.com/sites/ectrvideogroup/_layouts/15/embed.aspx?UniqueId=ecd8b702-4ea0-4fbb-b21d-38e247014fa6&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create';


@Component({
    selector: 'app-tutorial-modal',
    templateUrl: './tutorial-modal.component.html',
    styleUrls: ['./tutorial-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class TutorialModalComponent {
  public videoUrl: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) private data: TutorialDialogData) {
    if (this.data.isRequestor) {

      if (this.data.isEngineer) {
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(bothUrl);

      } else {

        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(requestorUrl);
      }
    } else {
      if (this.data.isEngineer) {
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(engineerUrl);
      }
    }
  }
}
