import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { ScenarioStatusIconComponent } from '../scenario-status-icon/scenario-status-icon.component';
import { DocumentExportDialogComponent } from './document-export-dialog.component';

const components = [DocumentExportDialogComponent];

@NgModule({
    imports: [CommonModule, MaterialModule, ScenarioStatusIconComponent, ...components],
    exports: components,
})
export class DocumentExportDialogModule {}
