import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { MatExpansionModule } from '@angular/material/expansion';
import { legendItems } from '@common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import {
  SelectionMode,
  selectionModeToken,
  SelectionService,
} from '@common/pxd-grid/selection.service';
import { PxDIndex } from '@models/pxd';
import { PxdCellsLegendComponent } from '../../../common/pxd-grid/pxd-cells-legend/pxd-cells-legend.component';
import { PxDGridComponent } from '../../../common/pxd-grid/pxd-grid.component';
@Component({
  selector: 'app-pxd-selector',
  templateUrl: './pxd-selector.component.html',
  standalone: true,
  imports: [MatExpansionModule, PxDGridComponent, PxdCellsLegendComponent],
  providers: [
    {
      provide: selectionModeToken,
      useValue: SelectionMode.SINGLE_SELECTION,
    },
    SelectionService,
  ],
})
export class PxdSelectorComponent {
  @Input() businessSegmentId: number;

  @Input() selectedPxD: PxDIndex;

  @Input() customData: PxDIndex[] = [];

  @Input() legendItems: any[] = [
    legendItems.WITH_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITH_MASTERDATA_WITHOUT_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_AND_PRINCIPALS,
    legendItems.WITHOUT_MASTERDATA_WITH_PRINCIPALS,
    legendItems.INACTIVE,
    legendItems.VALUE,
    legendItems.SELECTED,
  ];

  @Input() columnHeaderTemplate: TemplateRef<any> = null;

  @Input() rowHeaderTemplate: TemplateRef<any> = null;

  @Input() cellTemplate: TemplateRef<any> = null;

  @Output() selectedPxDChange = new EventEmitter<PxDIndex>();
}
