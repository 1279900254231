import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';

import { MediatorService } from '@app/mediator.service';
import {
  DownloadScenarioExportedToExcel,
  ScenarioClientExcelQuery,
} from '@app/models/backendModel';
import { SdkCallService } from '@app/sdk-call.service';
import { getProjectAction } from '@collections/projects/store/projects.actions';
import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';
import { ConfirmDialogComponent } from '@common/confirm-dialog/confirm-dialog.component';
import { ScenarioStatus } from '@common/enums';
import { deepClone, saveImportedDataAs } from '@common/utils';
import { UserRole } from '@core/store/core.reducer';
import { IProjectScenario } from '@models/project-scenario';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { ScenarioStatusIconComponent } from '../../common/scenario-status-icon/scenario-status-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgClass, NgFor, DecimalPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-project-scenarios',
    templateUrl: './project-scenarios.component.html',
    styleUrls: ['./project-scenarios.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgFor,
        MatTooltipModule,
        RouterLink,
        ScenarioStatusIconComponent,
        MatIconModule,
        MatRadioModule,
        MatSlideToggleModule,
        DecimalPipe,
        DatePipe,
    ],
})
export class ProjectscenriosComponent implements OnChanges {
  projectScenariosSection: UntypedFormGroup;
  projectId: number;
  changedArray: any = [];
  exportExcelArray: any = [];
  public completedStatus = ScenarioStatus.COMPLETED;
  @Input() projectScenarios: IProjectScenario[] = [];
  @Input() isProjectDisabled: boolean;
  @Input() defaultCurrency: string;
  selection = new SelectionModel<IProjectScenario>(true, []);
  baseCase = { scenarioId: '', isBaseCase: true };
  showPromptError: boolean;
  showPromptErrorExcel: boolean;
  projectScenariosCopy: IProjectScenario[] = [];
  public cancelledStatus = ScenarioStatus.CANCELLED;
  @ViewChild('container') container;
  headerObject = {
    number: { sort: false, upArrow: true, downArrow: true },
    scenarioName: { sort: false, upArrow: true, downArrow: true },
    status: { sort: false, upArrow: true, downArrow: true },
    totalCost: { sort: false, upArrow: true, downArrow: true },
    totalHours: { sort: false, upArrow: true, downArrow: true },
    totalGESSavings: { sort: false, upArrow: true, downArrow: true },
    submitted: { sort: false, upArrow: true, downArrow: true },
  };
  isSubmitButtonEnabled: boolean;
  noCTRS: boolean;

  private readonly currentUser$ = this.store.select(
    selectCurrentInitializedUser
  );

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly actr: ActivatedRoute,
    private readonly sdkService: SdkCallService,
    public readonly dialog: MatDialog,
    public readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly mediator: MediatorService
  ) {
    document.addEventListener('click', this.onClick.bind(this));
    this.actr.params.subscribe((data) => {
      this.projectId = parseInt(data.projectId, 10);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.projectScenarios != null &&
      changes.projectScenarios.currentValue != null
    ) {
      this.projectScenarios.forEach((scenario: any) => {
        scenario.selected = false;
        if (scenario.submitted == '1900-01-01T00:00:00') {
          scenario.submitted = null;
        }
        if (scenario.isBaseCase) {
          scenario.setAsBaseCase = scenario.scenarioId;
          this.baseCase.scenarioId = scenario.scenarioId;
        } else {
          scenario.setAsBaseCase = false;
        }
      });
      this.createScenarioFormGroup(this.projectScenarios);
      this.projectScenariosCopy = deepClone(this.projectScenarios);
    }
  }

  getScenarioTooltip(scenario): string {
    const tooltipParts = [
      scenario.status,
      scenario.isBaseCase && 'Base case',
      !!scenario.submitted && 'Submitted',
    ].filter((tooltipPart: string) => tooltipPart);
    return tooltipParts.join(' - ');
  }

  setFormArray(arg0: any): UntypedFormArray {
    const arr = arg0.map((service) => this.setFormGroup(service));

    return this.formBuilder.array(arr);
  }

  setFormGroup(data: any): UntypedFormGroup {
    const projectScenariosGroup = new UntypedFormGroup({});
    for (const key in data) {
      projectScenariosGroup.addControl(key, new UntypedFormControl(data[key]));
    }
    return projectScenariosGroup;
  }

  getProjectScenariosData() {
    const x = (
      this.projectScenariosSection?.controls
        ?.projectScenarios as UntypedFormArray
    )?.controls;
    return x;
  }

  sortDataDesc(columnName) {
    const newProjectsScenarios = JSON.parse(
      JSON.stringify(this.projectScenariosCopy)
    );
    newProjectsScenarios.sort((a, b) => {
      if (a[columnName]?.toLowerCase() < b[columnName]?.toLowerCase()) {
        return 1;
      }
      if (a[columnName]?.toLowerCase() > b[columnName]?.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    this.createScenarioFormGroup(newProjectsScenarios);
  }

  sortDataAsec(columnName) {
    const headerKeys = Object.keys(this.headerObject);
    headerKeys.forEach((x) => {
      if (x != columnName) {
        this.headerObject[x].sort = false;
        this.headerObject[x].upArrow = true;
        this.headerObject[x].downArrow = true;
      }
    });

    if (!this.headerObject[columnName].sort) {
      const newProjectsScenarios = deepClone(this.projectScenariosCopy);
      newProjectsScenarios.sort((a, b) => {
        if (a[columnName]?.toLowerCase() > b[columnName]?.toLowerCase()) {
          return 1;
        }
        if (a[columnName]?.toLowerCase() < b[columnName]?.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      this.createScenarioFormGroup(newProjectsScenarios);
      this.headerObject[columnName].sort = true;
      this.headerObject[columnName].upArrow = true;
      this.headerObject[columnName].downArrow = false;
    } else {
      this.sortDataDesc(columnName);
      this.headerObject[columnName].sort = false;
      this.headerObject[columnName].upArrow = false;
      this.headerObject[columnName].downArrow = true;
    }
  }

  sortNumericDataAsec(columnName) {
    const headerKeys = Object.keys(this.headerObject);
    headerKeys.forEach((x) => {
      if (x != columnName) {
        this.headerObject[x].sort = false;
        this.headerObject[x].upArrow = true;
        this.headerObject[x].downArrow = true;
      }
    });

    if (!this.headerObject[columnName].sort) {
      const newProjectsScenarios = JSON.parse(
        JSON.stringify(this.projectScenariosCopy)
      );
      newProjectsScenarios.sort((a, b) => {
        return a[columnName] - b[columnName];
      });
      this.createScenarioFormGroup(newProjectsScenarios);
      this.headerObject[columnName].sort = true;
      this.headerObject[columnName].upArrow = true;
      this.headerObject[columnName].downArrow = false;
    } else {
      this.sortNumericDataDesc(columnName);
      this.headerObject[columnName].sort = false;
      this.headerObject[columnName].upArrow = false;
      this.headerObject[columnName].downArrow = true;
    }
  }

  sortNumericDataDesc(columnName) {
    const newProjectsScenarios = JSON.parse(
      JSON.stringify(this.projectScenariosCopy)
    );
    newProjectsScenarios.sort((a, b) => {
      return b[columnName] - a[columnName];
    });

    this.createScenarioFormGroup(newProjectsScenarios);
  }

  onClick(event) {
    if (this.container.nativeElement.contains(event.target)) {
      this.noCTRS = false;
    }
  }

  decideArrowVisibility(columnName, arrow) {
    if (
      this.headerObject[columnName].upArrow !=
      this.headerObject[columnName].downArrow
    ) {
      if (this.headerObject[columnName][arrow]) {
        return 'showArrow';
      } else {
        return 'hideArrow';
      }
    } else {
      return 'hideArrow';
    }
  }

  decideExpandVisibility(columnName, arrow) {
    if (
      this.headerObject[columnName].upArrow &&
      this.headerObject[columnName].downArrow
    ) {
      return 'showArrow';
    } else {
      return 'hideArrow';
    }
  }

  submitScenario(scenario): void {
    if (this.isProjectDisabled) {
      return;
    }

    const scenarioSubmitConfirmation = `You are going to submit a scenario. After this you will not be able to take further action on that scenario.`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Submit scenario?',
        message: scenarioSubmitConfirmation,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const scenarioValue = scenario.value;
        scenarioValue.submitted = new Date();
        this.sdkService
          .submitProjectScenariosData(this.projectId, [scenarioValue])
          .pipe(withLatestFrom(this.currentUser$))
          .subscribe(([data, currentUser]) => {
            this.snackBar.open('scenarios submitted successfully', null, {
              duration: 2000,
            });

            this.store.dispatch(
              getProjectAction({
                context:
                  'ProjectscenariosComponent::submitScenario/afterClosed',
                payload: {
                  projectId: this.projectId,
                  context: UserRole.REQUESTOR,
                },
              })
            );
            scenario.patchValue(data[0]);
          });
      } else {
        scenario.get('submitted').setValue(false);
      }
    });
  }

  downloadExcel(scenarioId: number): void {
    this.googleAnalyticsService.event(
      'project_download_excel',
      'file_download'
    );
    this.mediator
      .sendWithBlobResponse<DownloadScenarioExportedToExcel>(
        'DownloadScenarioExportedToExcel',
        { id: scenarioId }
      )
      .subscribe((scenarioSaved) => {
        if (!scenarioSaved) {
          this.noCTRS = true;
        }
      });
  }

  downloadClientExcel(scenarioId: number): void {
    this.googleAnalyticsService.event(
      'project_download_client_excel',
      'file_download'
    );

    this.mediator
      .sendWithBlobResponse<ScenarioClientExcelQuery>(
        'ScenarioClientExcelQuery',
        { id: scenarioId }
      )
      .subscribe((clientDataSaved) => {
        if (!clientDataSaved) {
          this.noCTRS = true;
        }
      });
  }

  downloadMDR(scenarioId): void {
    this.googleAnalyticsService.event('project_download_mdr', 'file_download');
    this.sdkService.downloadMDR(scenarioId, 0).subscribe((data) => {
      if (!saveImportedDataAs(data)) {
        this.noCTRS = true;
      }
    });
  }

  selectAsBaseCase(scenario): void {
    if (this.isProjectDisabled) {
      return;
    }

    const selectAsBaseCaseConfirmation = `Are you sure to select this scenario as the base case?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        message: selectAsBaseCaseConfirmation,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        scenario
          .get('setAsBaseCase')
          .patchValue(scenario.get('scenarioId')?.value);
        this.baseCase.scenarioId = scenario.get('scenarioId')?.value;
        (
          this.projectScenariosSection.get(
            'projectScenarios'
          ) as UntypedFormArray
        ).controls.forEach((scenarioForm) => {
          if (
            scenarioForm.get('setAsBaseCase').value != this.baseCase.scenarioId
          ) {
            scenarioForm.get('setAsBaseCase').setValue(false);
          }
        });

        this.sdkService
          .updateBaseCaseScenario(this.projectId, this.baseCase)
          .subscribe(() => {
            this.snackBar.open(
              'Scenario is saved as base case successfully',
              null,
              {
                duration: 2000,
              }
            );
            this.sdkService.baseCaseChanged.next(true);
          });
      } else {
        scenario.get('setAsBaseCase').patchValue(false);
      }
    });
  }

  isBaseCase(baseCase) {
    return baseCase;
  }

  private createScenarioFormGroup(projectScenarios: IProjectScenario[]) {
    this.projectScenariosSection = new UntypedFormGroup({});
    this.projectScenariosSection.addControl(
      'projectScenarios',
      this.setFormArray(projectScenarios)
    );

    if (this.isProjectDisabled) {
      this.projectScenariosSection.disable({
        emitEvent: true,
        onlySelf: false,
      });
    }
  }
}
