import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardComponent } from './card.component';

const components = [CardComponent];

@NgModule({
    imports: [CommonModule, ...components],
    exports: components,
})
export class CardModule {}
