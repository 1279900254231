import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';

import { AdminPxdGridCellComponent } from './admin-pxd-grid-cell/admin-pxd-grid-cell.component';
import { AdminUserPxdGridCellComponent } from './admin-user-pxd-grid-cell/admin-user-pxd-grid-cell.component';
import { EngineerPxdGridCellComponent } from './engineer-pxd-grid-cell/engineer-pxd-grid-cell.component';
import { InfoPxdGridCellComponent } from './info-pxd-grid-cell/info-pxd-grid-cell.component';
import { PxdCellsLegendComponent } from './pxd-cells-legend/pxd-cells-legend.component';
import { PxDGridComponent } from './pxd-grid.component';
import { RequestorPxdGridCellComponent } from './requestor-pxd-grid-cell/requestor-pxd-grid-cell.component';
import { ScenarioSummaryPxdGridCellComponent } from './scenario-summary-pxd-grid-cell/scenario-summary-pxd-grid-cell.component';

const components = [
  AdminPxdGridCellComponent,
  AdminUserPxdGridCellComponent,
  EngineerPxdGridCellComponent,
  InfoPxdGridCellComponent,
  PxdCellsLegendComponent,
  PxDGridComponent,
  RequestorPxdGridCellComponent,
  ScenarioSummaryPxdGridCellComponent,
];

@NgModule({
    imports: [CommonModule, MaterialModule, ...components],
    exports: components,
})
export class PxdGridModule {}
