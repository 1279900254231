import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { TutorialModalComponent } from './tutorial-modal/tutorial-modal.component';

const components = [TutorialModalComponent];

@NgModule({
    imports: [SharedModule, MaterialModule, ...components],
    exports: components,
})
export class WelcomeTutorialModule {}
