import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import {
  selectCurrentUser,
  selectCurrentUserIsAdmin,
  selectMainAccountIsAdmin,
  selectShortRoleName,
} from '@collections/users/store/users.selectors';
import { LayoutService } from '@core/layout/layout.service';
import { environment } from '@environments/environment';
import { MatDividerModule } from '@angular/material/divider';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        MatToolbarModule,
        RouterLink,
        PortalModule,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        UserAvatarComponent,
        MatDividerModule,
        AsyncPipe,
    ],
})
export class HeaderComponent {
  public actualAdmin$ = this.store.select(selectMainAccountIsAdmin);

  public isAdmin$ = this.store.select(selectCurrentUserIsAdmin);

  public currentUser$ = this.store.select(selectCurrentUser);

  public userSignature$ = this.currentUser$.pipe(
    map((user) => user.firstName[0] + user.lastName[0])
  );

  public shortRoleName$ = this.store.select(selectShortRoleName);

  public displaySurveyBanner = Date.now() <= new Date('2023-04-17').getTime();

  constructor(
    private readonly authService: MsalService,
    public readonly layoutService: LayoutService,
    private readonly store: Store
  ) {}

  public logout() {
    if (environment.authentication) {
      this.authService.logout();
    }
  }
}
