import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusLabelComponent } from './status-label.component';

const components = [
  StatusLabelComponent
]

@NgModule({
    exports: components,
    imports: [
        CommonModule,
        ...components
    ]
})
export class StatusLabelModule { }
