import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarNotificationService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public showNotification(
    message: string,
    duration = 2000
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, null, {
      duration,
    });
  }
}
