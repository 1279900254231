import { Component, Inject } from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { selectCurrentInitializedUser } from '@collections/users/store/users.selectors';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangeLogTableComponent } from '../change-log-table/change-log-table.component';
import { NgIf, NgFor, NgClass, AsyncPipe, DatePipe } from '@angular/common';

export interface ChangeLogDialogData<A = any> {
  title: string;
  message?: string;
  readonly: boolean;
  notifyUsers?: boolean;
  changesUserName: string;
  changesUserEmail: string;
  changesDate: Date;
  changesList: any[];
  showNotifyUsersCheckbox?: boolean;
  buttons: { label: string; action: A; class?: string }[];
}

export interface ChangeLogDialogResult<A = any> {
  action: A;
  notifyUsers?: boolean;
}

@Component({
    selector: 'app-change-log-dialog',
    templateUrl: './change-log-dialog.component.html',
    styleUrls: ['./change-log-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        NgIf,
        ChangeLogTableComponent,
        MatCheckboxModule,
        MatTooltipModule,
        ReactiveFormsModule,
        NgFor,
        MatButtonModule,
        NgClass,
        AsyncPipe,
        DatePipe,
    ],
})
export class ChangeLogDialogComponent {
  public notifyAboutChangesFormControl = new UntypedFormControl(
    this.data.notifyUsers
  );

  public changesDate = this.data.changesDate;

  public changesList = this.data.changesList;

  public readonly = this.data.readonly;

  private readonly currentUser$ = this.store.select(
    selectCurrentInitializedUser
  );

  public changesOwner$ = this.currentUser$.pipe(
    map(({ fullName }) =>
      fullName !== this.data.changesUserName
        ? {
            userName: this.data.changesUserName,
            email: this.data.changesUserEmail,
          }
        : { userName: 'You' }
    )
  );

  public buttons = this.data.buttons;

  public showNotifyUsersCheckbox = this.data.showNotifyUsersCheckbox;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeLogDialogData,
    private readonly matDialogRef: MatDialogRef<ChangeLogDialogComponent>,
    private readonly store: Store
  ) {}

  public close() {
    this.matDialogRef.close();
  }
}
