import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { shareReplay } from 'rxjs/operators';

import { selectAllActiveBusinessSegments } from '@collections/business-segments/store/business-segments.selectors';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-business-segment-selector',
    templateUrl: './business-segment-selector.component.html',
    styleUrls: ['./business-segment-selector.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatRadioModule,
        ReactiveFormsModule,
        FormsModule,
        NgFor,
        AsyncPipe,
    ],
})
export class BusinessSegmentSelectorComponent {
  @Input() public selectedBusinessSegmentId: number;
  @Output() public selectedBusinessSegmentIdChange = new EventEmitter<number>();

  public businessSegments$ = this.store
    .select(selectAllActiveBusinessSegments)
    .pipe(shareReplay(1));

  constructor(private readonly store: Store) {}

  public selectionChange(businessSegmentId: number) {
    this.selectedBusinessSegmentIdChange.emit(businessSegmentId);
  }
}
