import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiveSaveSnackbarService {
  public readonly trigger$ = new Subject<boolean>();
  public readonly close$ = new Subject<boolean>();

  open(): void {
    this.trigger$.next(true);
  }

  close(success: boolean): void {
    this.close$.next(success);
  }
}
