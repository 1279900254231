import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { selectProjectNameByProjectIdFactory } from '@app/collections/projects-names/store/projects-names.selectors';
import { selectProjectByIdFactory$ } from '@app/collections/projects/store/projects.selectors';
import { selectCurrentUser } from '@app/collections/users/store/users.selectors';
import {
  selectCurrentRouteParamFactory,
  selectCurrentRouteUserRole,
} from '@core/store/core.selectors';
import { filter, switchMap, take } from 'rxjs/operators';
import {
  EditOwnerDialogComponent,
  IEditOwnerDialogData,
} from '../edit-owner-dialog/edit-owner-dialog.component';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatTabsModule,
        RouterLinkActive,
        RouterLink,
        RouterOutlet,
        AsyncPipe,
    ],
})
export class ProjectComponent implements OnInit {
  public heading: string;
  private projectOwnerId: number;
  private isCurrentUserAdmin: boolean;
  private currentUserId: number;
  public isChangeProjectOwnerDisabled = false;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.store
      .select(selectCurrentRouteUserRole)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((role) =>
          this.routeProjectId$.pipe(
            switchMap((projectId) =>
              selectProjectByIdFactory$(this.store, projectId, role)
            )
          )
        ),
        filter((projectData) => !!projectData)
      )
      .subscribe((projectData) => {
        this.projectOwnerId = projectData.requestorId;
      });

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userData) => {
        this.currentUserId = userData.userId;
        this.isCurrentUserAdmin = userData.isAdmin;
      });

    this.isChangeProjectOwnerDisabled = this.isCurrentUserAdmin
      ? false
      : this.currentUserId !== this.projectOwnerId;
  }

  public routeProjectId$ = this.store.select(
    selectCurrentRouteParamFactory('projectId')
  );

  public projectName$ = this.routeProjectId$.pipe(
    switchMap((projectId) =>
      this.store.select(selectProjectNameByProjectIdFactory(projectId))
    )
  );

  openEditOwnerDialog(): void {
    this.routeProjectId$.pipe(take(1)).subscribe((projectId) => {
      this.dialog.open(EditOwnerDialogComponent, {
        height: '94%',
        width: '20%',
        position: { right: '0px', bottom: '5px' },
        autoFocus: false,
        data: { projectId } as IEditOwnerDialogData,
      });
    });
  }
}
