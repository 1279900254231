import { NgModule } from '@angular/core';

import { BreadcrumbsModule } from '@app/breadcrumbs/breadcrumbs.module';
import { UserAvatarModule } from '@app/user-avatar/user-avatar.module';
import { SharedModule } from '@shared/shared.module';

import { CommentComponent } from './comment/comment.component';
import { EventLogComponent } from './event-log.component';
import { FilterComponent } from './filter/filter.component';
import { MessageComponent } from './message/message.component';
import { ReplyCommentComponent } from './reply-comment/reply-comment.component';

@NgModule({
    imports: [SharedModule, UserAvatarModule, BreadcrumbsModule, EventLogComponent,
        MessageComponent,
        CommentComponent,
        ReplyCommentComponent,
        FilterComponent],
    exports: [EventLogComponent],
})
export class EventLogModule {}
