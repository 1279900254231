import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IPxD } from '@app/models/pxd';

import { CtrStatus } from '@common/enums';
import { NgIf } from '@angular/common';
export interface IValueWithIndex {
  value: string;
  index: number;
}
@Component({
    selector: 'app-scenario-summary-pxd-grid-cell',
    templateUrl: 'scenario-summary-pxd-grid-cell.component.html',
    styleUrls: ['./scenario-summary-pxd-grid-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class ScenarioSummaryPxdGridCellComponent implements OnChanges {
  public readonly CtrStatus = CtrStatus;

  @Input() public pxd: IPxD = null;

  @Input()
  @HostBinding('class.ctr-status-selected')
  public selected = false;

  @Input()
  @HostBinding('class.selectable')
  public selectable = false;

  @Input()
  public valueWithIndex: IValueWithIndex;

  @Input()
  public ctrStatus: CtrStatus;

  @Input()
  public requestRevision: boolean;

  @Input()
  @HostBinding('class.pxd-disabled')
  public disabled = false;

  @HostBinding('class.pxd-with-master-data')
  public withMasterdata = false;

  @HostBinding('class.pxd-with-principals')
  public withPrincipals = false;

  @HostBinding('class')
  public colorClass = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pxd && this.pxd) {
      this.withMasterdata = this.pxd.hasMasterData;
      this.withPrincipals = this.pxd.hasPrincipals;
    }

    this.colorClass = this.valueWithIndex?.value
      ? `cell-color-${this.valueWithIndex.index}`
      : '';
  }

  public getCellText(): string {
    if (
      (!this.ctrStatus || this.ctrStatus === CtrStatus.COMPLETED) &&
      !this.requestRevision
    )
      return this.valueWithIndex.value;
    if (this.ctrStatus === CtrStatus.CANCELLED) {
      return 'Cancelled';
    }
    if (
      [CtrStatus.NOT_STARTED, CtrStatus.IN_PROGRESS].includes(
        this.ctrStatus as CtrStatus
      ) ||
      this.requestRevision
    )
      return 'Pending revision';
    return '';
  }
}
