import { NgModule } from '@angular/core';

import { NumbersInputGuardDirective } from './numbers-input-guard.directive';

const components = [NumbersInputGuardDirective];

@NgModule({
    imports: [...components],
    exports: components,
})
export class NumbersInputGuardModule {}
