import { HttpResponse } from '@angular/common/http';
import { format } from 'date-fns-tz';
import { saveAs } from 'file-saver';
import { distinctUntilChanged } from 'rxjs/operators';

import { GetEngineeringRoleResult } from '@app/models/backendModel';
import { EngineeringRoleType } from '@common/enums';
import { Block } from '@models/block';
import { getRouterSelectors } from '@ngrx/router-store';

export const { selectRouteParams } = getRouterSelectors();
export const getRolePrefix: (
  engineeringRole: GetEngineeringRoleResult
) => 'Eng' | 'Dft' = (engineeringRole: GetEngineeringRoleResult) => {
  switch (engineeringRole.roleType) {
    case EngineeringRoleType.ENGINEERING:
      return 'Eng';
    case EngineeringRoleType.DRAFTING:
      return 'Dft';
  }
};
export const getRolePrefixByRoleType: (
  roleType: EngineeringRoleType
) => 'Eng' | 'Dft' = (roleType: EngineeringRoleType) => {
  switch (roleType) {
    case EngineeringRoleType.ENGINEERING:
      return 'Eng';
    case EngineeringRoleType.DRAFTING:
      return 'Dft';
  }
};

export const checkIfAdditionalActivity = (block: Block) => block.order === 99;

export const saveImportedDataAs = (data: HttpResponse<BlobPart>) => {
  if (!data.headers.get('Content-Type').includes('application/json')) {
    const blob = new Blob([data.body], {
      type: data.headers.get('Content-Type'),
    });
    let saveFileName;
    const contentDisposition = data.headers.get('content-disposition');
    const matches = /filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/gi.exec(
      contentDisposition
    );
    const fileName = (matches[1] || 'untitled').trim();
    if (fileName.startsWith('"') && fileName.endsWith('"')) {
      saveFileName = fileName.substring(1, fileName.length - 1);
    } else {
      saveFileName = fileName;
    }
    saveAs(blob, saveFileName);

    return true;
  }
  return false;
};

export const deepClone = <T = any>(obj: T) =>
  JSON.parse(JSON.stringify(obj)) as T;

/**
 * Converts local date to UTC+00
 * Removes hours from local time so whatever is displayed in date selectors ignores
 * hours and is treated as just date withour localisation hours
 */
export function formToServerDateConversion(value: Date | string) {
  if (!value) {
    return null;
  }

  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  if (!value) {
    return null;
  }

  return format(value, 'yyyy-MM-dd');
}

export function monthDiff(dateFrom, dateTo) {
  const months =
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear());

  const dayDiff = dateTo.getDate() - dateFrom.getDate();

  if (dayDiff <= -14) {
    return months - 0.5;
  } else if (dayDiff <= 0) {
    return months;
  } else if (dayDiff <= 14) {
    return months + 0.5;
  }
  return months + 1;
}

export function distinctUntilChangedObject<T = any>() {
  return distinctUntilChanged<T>(
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );
}

/**
 * Enter array of arrays of objects to create array of objects combined from all properties.
 * @example
 * ```js
 * allCombinations([{ x: 'A' }, { x: 'B' }], [{ y: '0'}])
 * // result: [
 * // { x: 'A', y: '0'},
 * // { x: 'B', y: '0'}
 * //]
 * ```
 * ```js
 * allCombinations([{ x: 'A' }, { x: 'B' }], [{ y: '0'}, { z: '1' }])
 * // result: [
 * // { x: 'A', y: '0'},
 * // { x: 'B', y: '0'},
 * // { x: 'A', z: '1'},
 * // { x: 'B', z: '1'}
 * //]
 * ```
 */
export function allCombinations<T extends Record<string, any>>(
  config: Partial<T>[][]
): T[] {
  return config.reduce<T[]>(
    (r, values) => [
      ...r.reduce(
        (a, prev) => [...a, ...values.map((v) => ({ ...prev, ...v } as T))],
        []
      ),
    ],
    [{} as T]
  );
}
