import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

export interface DialogData {
  title: string;
  message: string;
}

@Component({
    selector: 'app-errorpopup',
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule],
})
export class ErrorPopUpComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onConfirm(): void {
    this.dialogRef.close(true);
  }
}
