import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SdkCallService } from '@app/sdk-call.service';

import { CompareScenarioResponse } from '@app/models/backendModel';
import { getScenarioComparisonAction } from './store/scenario-comparison.actions';
import {
  selectAllScenarioComparison,
  selectScenarioComparisonItemsByProjectIdFactory,
} from './store/scenario-comparison.selectors';
import { ScenarioComparisonColumnComponent } from './scenario-comparison-column/scenario-comparison-column.component';
import { ScenarioComparisonCellComponent } from './scenario-comparison-cell/scenario-comparison-cell.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-scenario-comparison',
    templateUrl: './scenario-comparison.component.html',
    styleUrls: ['./scenario-comparison.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ScenarioComparisonCellComponent,
        ScenarioComparisonColumnComponent,
        AsyncPipe,
    ],
})
export class ScenarioComparisonComponent implements OnInit {
  @Input() defaultCurrency: string;
  @Input() projectId: number;

  public compareScenarioItems$: Observable<CompareScenarioResponse[]>;
  public baseCaseScenario$: Observable<CompareScenarioResponse>;
  public compareScenarioItemsWithoutBaseCase$: Observable<
    CompareScenarioResponse[]
  >;
  public ctrTypes: string[];

  constructor(
    public readonly sdkService: SdkCallService,
    public readonly store$: Store
  ) {}

  public ngOnInit(): void {
    this.store$.select(selectAllScenarioComparison);
    this.store$.dispatch(
      getScenarioComparisonAction({
        payload: { projectId: Number(this.projectId) },
      })
    );
    this.compareScenarioItems$ = this.store$.select(
      selectScenarioComparisonItemsByProjectIdFactory(this.projectId)
    );
    this.baseCaseScenario$ = this.compareScenarioItems$.pipe(
      map((x) => x.find((scenario) => scenario?.baseCase))
    );
    this.compareScenarioItemsWithoutBaseCase$ = this.compareScenarioItems$.pipe(
      map((x) => x.filter((scenario) => !scenario?.baseCase))
    );
    this.compareScenarioItems$.subscribe((compareItems) => {
      this.ctrTypes = this.getCtrTypes(compareItems);
    });
  }

  public getCtrTypes(scenarios: CompareScenarioResponse[]): string[] {
    if (scenarios) {
      let x: string[] = [];
      scenarios.forEach((element) => {
        x = x.concat(element.scenarioCTRs.map((ctr) => ctr.ctrShortName));
      });
      // Set for the sake of removing duplicate shortNames
      return [...new Set(x)].sort((a, b) => a.localeCompare(b));
    }
  }
}
