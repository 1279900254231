import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class CardComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() titleFontSize = '21px';
  @Input() disabled: boolean;
  @Input() fitToContent: boolean;
  @Input() customContentPaddingSize: string | number;
}
