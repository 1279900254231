import {
  Overlay,
  OverlayModule,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  HostListener,
  inject,
  Injector,
  Input,
  OnDestroy,
  StaticProvider,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CalendarizationCtrsToEditService } from '@app/calendarization/calendarization-ctrsToEdit.service';
import { getCtrsCalendarizationAction } from '@app/calendarization/store/ctrs-calendarization.actions';
import { IntegerPipe } from '@app/common/pipe/integer.pipe';
import { OneDecimalPipe } from '@app/common/pipe/one-decimal.pipe';
import { MediatorService } from '@app/mediator.service';
import {
  CTRExcelQuery,
  CTRStatus,
  FteDistributionForMatrix,
  HoursDistributionForMatrix,
  ScenarioCTRsClientExcelQuery,
} from '@app/models/backendModel';
import {
  CalendarizationDetailedDialogData,
  CalendarizationDetailedViewComponent,
} from '../calendarization-detailed-view/calendarization-detailed-view.component';
import { HoursDistributionInfoDialogComponent } from '../calendarization-table-roles/hours-distribution-info-dialog/hours-distribution-info-dialog.component';
import { CalendarizationTableSharedModule } from '../calendarization-table-shared/calendarization-table-shared-module';
import {
  CalendarizationTableSharedService,
  MonthData,
  YearData,
} from '../calendarization-table-shared/calendarization-table-shared.service';
import { CalendarizationViewType } from '../calendarization-table-shared/calendarization-table-total-in-month/calendarization-table-total-in-month.component';
import { CalendarizationForProject } from '../store/ctrs-calendarization.reducer';
import {
  selectCalendarizationCTRsByScenarioIdFactory,
  selectCalendarizationDataLoaded,
} from '../store/ctrs-calendarization.selectors';
import { CalendarizationCtrStartdateEditionComponent } from './calendarization-ctr-startdate-edition/calendarization-ctr-startdate-edition.component';
import { CalendarizationCtrTooltipComponent } from './calendarization-ctr-tooltip/calendarization-ctr-tooltip.component';
import { CalendarizationTableCTRsServiceModule } from './services/calendarization-table-ctrs-service-module';
import { CalendarizationTableCTRsService } from './services/calendarization-table-ctrs.service';

export interface CtrMatrixHoursDistribution {
  scenarioCTRId: number;
  ctrShortName: string;
  owner: string;
  totalNumberOfHours: number;
  years: HoursDistributionForMatrix[];
  ctrStatus: number;
}

export interface CtrMatrixFTEDistribution {
  scenarioCTRId: number;
  ctrShortName: string;
  owner: string;
  totalNumberOfFte: number;
  years: FteDistributionForMatrix[];
  ctrStatus: number;
}

interface CrtWithBarPosition extends CalendarizationForProject {
  style: {
    left: string;
    width: string;
  };
}

export enum CalendarizationMode {
  'REQUESTOR',
  'ENGINEERING',
}
@Component({
  selector: 'app-calendarization-table-ctrs',
  templateUrl: './calendarization-table-ctrs.component.html',
  styleUrls: ['./calendarization-table-ctrs.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CalendarizationCtrTooltipComponent,
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTooltipModule,
    OverlayModule,
    RouterModule,
    CalendarizationDetailedViewComponent,
    CalendarizationTableCTRsServiceModule,
    CalendarizationTableSharedModule,
    OneDecimalPipe,
    IntegerPipe,
  ],
  providers: [CalendarizationTableCTRsService, DecimalPipe],
})
export class CalendarizationTableCTRsComponent implements OnDestroy {
  constructor(
    private readonly store: Store,
    public readonly overlay: Overlay,
    public readonly overlayPositionBuilder: OverlayPositionBuilder,
    public readonly dialog: MatDialog,
    private readonly checkedCtrsToEditService: CalendarizationCtrsToEditService,
    private readonly mediator: MediatorService,
    private readonly calendarizationTableSharedService: CalendarizationTableSharedService,
    private readonly calendarizationTableCTRsService: CalendarizationTableCTRsService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  private readonly destroyRef = inject(DestroyRef);

  @Input() public set calendarizationMode(mode: CalendarizationMode) {
    switch (mode) {
      case CalendarizationMode.REQUESTOR:
        this.isRequestorMode = true;
        break;
      case CalendarizationMode.ENGINEERING:
        this.isRequestorMode = false;
        break;
    }
  }

  public isRequestorMode = true;
  public chartViewType = CalendarizationViewType;
  public fteNumberOfHours = 147;
  public selectedView: CalendarizationViewType = CalendarizationViewType.CHART;
  public showCtrOwner = false;
  public isAnyCtrCheckboxSelected: boolean;
  public areAllCtrCheckboxesSelected = false;
  public isOnlyOneCtrCheckboxSelected = false;
  public overlayRef: OverlayRef;
  public checkboxStates = {};
  public tooltipIsVisible = false;
  private selectedCtrBar: HTMLElement;

  @Input() public set scenarioId(value: number) {
    this.scenarioId$.next(value);
  }
  public scenarioId$ = new ReplaySubject<number>(1);
  public isCtrsDataLoaded$ = this.store.select(selectCalendarizationDataLoaded);

  public ctrs$ = this.scenarioId$.pipe(
    filter((scenarioId) => !isNaN(scenarioId)),
    distinctUntilChanged(),
    switchMap((scenarioId) => {
      return this.loadCalendarizationFromStore(scenarioId);
    }),
    tap((ctrs) => {
      this.checkboxStates = {};
      this.isAnyCtrCheckboxSelected = false;
      this.isOnlyOneCtrCheckboxSelected = false;
      this.areAllCtrCheckboxesSelected = false;
      ctrs.forEach((ctr) => {
        this.checkboxStates[ctr.scenarioCTRId] = false;
      });
    }),
    shareReplay(1)
  );

  public loadCalendarizationFromStore(
    scenarioId: number
  ): Observable<CalendarizationForProject[]> {
    this.store.dispatch(
      getCtrsCalendarizationAction({ payload: { scenarioId } })
    );
    return this.store.select(
      selectCalendarizationCTRsByScenarioIdFactory(scenarioId)
    );
  }

  public getHoursForYears(ctrs: CalendarizationForProject[]): YearData[] {
    if (ctrs.length === 0) {
      return [];
    }
    const minYear = this.calendarizationTableCTRsService.getMinStartYear(ctrs);
    const maxYear = this.calendarizationTableCTRsService.getMaxEndYear(ctrs);
    const minDateForPeriods = this.getPeriodStartDateForTheYear(minYear);
    const distributions = ctrs.reduce(
      (acc, curr) => {
        acc.hours = [...acc.hours, ...curr.hoursDistributionForYears];
        acc.fte = [...curr.fteDistributionForYears, ...acc.fte];
        return acc;
      },
      {
        hours: [] as HoursDistributionForMatrix[],
        fte: [] as FteDistributionForMatrix[],
      }
    );
    const yearPeriods =
      this.calendarizationTableSharedService.getYearsMonthsSplitWithTotals(
        minDateForPeriods,
        new Date(maxYear, 11, 31),
        true,
        distributions.hours,
        distributions.fte
      );

    return yearPeriods;
  }
  public getPeriodStartDateForTheYear(year: number): Date {
    return new Date(year, 0, 1);
  }

  public getTimeRangesForCrts(
    ctrs$: Observable<CalendarizationForProject[]>
  ): Observable<YearData[]> {
    return ctrs$.pipe(
      map((ctrs) => {
        ctrs = ctrs.sort(
          (a, b) => a.startDateAsDate.getTime() - b.startDateAsDate.getTime()
        );

        return this.getHoursForYears(ctrs);
      })
    );
  }

  public timeRange$ = this.getTimeRangesForCrts(this.ctrs$);

  getDaysBetweenDates(start: Date, end: Date): number {
    return (
      Math.round((end.getTime() - start.getTime()) / (3600 * 24 * 1000)) + 1
    );
  }

  calculateBarPosition(
    ctr: CalendarizationForProject,
    minDate: Date,
    periodsDays: number[]
  ): CrtWithBarPosition {
    const startDate = ctr.startDateAsDate;
    const endDate = ctr.endDateAsDate;

    let daysFromStart = this.getDaysBetweenDates(minDate, startDate) - 1;

    let barLength = this.getDaysBetweenDates(startDate, endDate);

    let barOffset = 0;
    let barWidth = 0;
    let barStartOffset = 0;
    let barWidthEndOffset = 0;

    for (const periodDays of periodsDays) {
      if (daysFromStart >= periodDays) {
        daysFromStart -= periodDays;
        barOffset++;
      } else {
        barStartOffset = (daysFromStart / periodDays) * 80; // calculate additional offset for ctrs that start in the middle of a period
        break;
      }
    }

    for (const periodDays of periodsDays.slice(barOffset)) {
      if (barLength >= periodDays) {
        barLength -= periodDays;
        barWidth++;
      } else {
        barWidthEndOffset = (barLength / periodDays) * 80; // calculate additional offset for ctrs that end in the middle of a period
        break;
      }
    }

    return {
      ...ctr,
      style: {
        left: `${barOffset * 80 + barStartOffset}px`,
        width: `${barWidth * 80 + barWidthEndOffset}px`,
      },
    };
  }

  public ctrsDataForBarsView$ = combineLatest([
    this.timeRange$,
    this.ctrs$,
  ]).pipe(
    filter(([yearData, ctrs]) => yearData.length > 0 && ctrs.length > 0),
    map(([yearData, ctrs]) => {
      const minDateForPeriods = new Date(yearData[0].yearIndex, 0);
      const daysInMonth = yearData.reduce(
        (r: number[], { months }) => [
          ...r,
          ...months.map(({ periodDays: daysInMonth }) => daysInMonth),
        ],
        []
      );

      return ctrs.map((ctr) =>
        this.calculateBarPosition(ctr, minDateForPeriods, daysInMonth)
      );
    })
  );

  public isCtrStartedWithNoActivities(
    ctrStatus: number,
    totalHours: number
  ): boolean {
    return ctrStatus === CTRStatus.InProgress && totalHours === 0;
  }

  public isCtrNotStarted(ctrStatus: number): boolean {
    return ctrStatus === CTRStatus.NotStarted;
  }

  private readonly checkedCtrs$ = this.ctrs$.pipe(
    map((data) => data.filter((ctr) => this.checkboxStates[ctr.scenarioCTRId]))
  );

  public ctrsDataForMatrixView$: Observable<CtrMatrixHoursDistribution[]> =
    this.ctrs$.pipe(
      map((ctrs) => {
        return ctrs.map((ctr) => {
          const years = ctr.hoursDistributionForYears;
          return {
            scenarioCTRId: ctr.scenarioCTRId,
            ctrShortName: ctr.ctrShortName,
            owner: ctr.owner,
            totalNumberOfHours: ctr.totalHours,
            years: years,
            ctrStatus: ctr.ctrStatus,
          };
        });
      })
    );

  public ctrsDataForFTEView$: Observable<CtrMatrixFTEDistribution[]> =
    this.ctrs$.pipe(
      map((ctrs) => {
        return ctrs.map((ctr) => {
          return this.getFteDataFromFteDistribution(ctr);
        });
      })
    );

  private getFteDataFromFteDistribution(
    ctr: CalendarizationForProject
  ): CtrMatrixFTEDistribution {
    return {
      scenarioCTRId: ctr.scenarioCTRId,
      ctrShortName: ctr.ctrShortName,
      owner: ctr.owner,
      totalNumberOfFte: ctr.totalFte,
      ctrStatus: ctr.ctrStatus,
      years: ctr.fteDistributionForYears.map((year) => ({
        ...year,
        ftePeriods: year.ftePeriods.map((period) => ({
          ...period,
          fteInPeriod: period.fteInPeriod,
        })),
      })),
    };
  }

  isAnyCtrChecked(): void {
    const selectedValues = Object.values(this.checkboxStates);

    this.areAllCtrCheckboxesSelected =
      selectedValues.length > 0 &&
      selectedValues.every((isSelected) => isSelected);
    this.isAnyCtrCheckboxSelected =
      this.areAllCtrCheckboxesSelected ||
      selectedValues.some((isSelected) => isSelected);

    this.isOnlyOneCtrCheckboxSelected =
      Object.values(this.checkboxStates).filter((isSelected) => isSelected)
        .length === 1;
  }

  public toggleCheckbox(ctrId: string): void {
    this.checkboxStates[ctrId] = !this.checkboxStates[ctrId];
    this.isAnyCtrChecked();
  }

  showTooltip(event: MouseEvent, ctr: CalendarizationForProject): void {
    event.stopPropagation();

    if (this.tooltipIsVisible) {
      this.hideTooltip();
    }

    if (this.selectedCtrBar) {
      this.selectedCtrBar.classList.remove('selected-ctr-bar');
    }
    this.selectedCtrBar = event.target as HTMLElement;
    this.selectedCtrBar.classList.add('selected-ctr-bar');

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo({ x: event.x - 435, y: event.y })
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
    });

    const providers: StaticProvider[] = [{ provide: 'ctrData', useValue: ctr }];
    const injector = Injector.create({ providers });

    this.overlayRef.attach(
      new ComponentPortal(CalendarizationCtrTooltipComponent, null, injector)
    );

    this.tooltipIsVisible = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    this.hideTooltip();
  }

  hideTooltip(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
      this.selectedCtrBar.classList.remove('selected-ctr-bar');
      this.tooltipIsVisible = false;
    }
  }

  toggleTooltip(event: MouseEvent, ctr: any): void {
    if (this.tooltipIsVisible) {
      this.hideTooltip();
    } else {
      this.showTooltip(event, ctr);
    }
    this.tooltipIsVisible = !this.tooltipIsVisible;
  }

  openCtrStartDateEditDialog(): void {
    this.googleAnalyticsService.event(
      'click_open_set_ctr_start_date_dialog',
      'calendarization'
    );
    this.checkedCtrs$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((checkedCtrs) => {
        this.checkedCtrsToEditService.setCheckedCtrsToEdit(checkedCtrs);
      });

    this.dialog
      .open(CalendarizationCtrStartdateEditionComponent, {
        height: '94%',
        width: '25%',
        position: { right: '0px', bottom: '5px' },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.unselectAllCtrs());
  }

  public selectAllChanged(checked: boolean): void {
    if (checked) {
      this.selectAllCtrs();
    } else {
      this.unselectAllCtrs();
    }
  }

  public toggleSelectAll(): void {
    this.areAllCtrCheckboxesSelected = !this.areAllCtrCheckboxesSelected;
    this.selectAllChanged(this.areAllCtrCheckboxesSelected);
  }

  public selectAllCtrs(): void {
    for (const key in this.checkboxStates) {
      this.checkboxStates[key] = true;
    }
    if (Object.keys(this.checkboxStates).length === 1) {
      this.isOnlyOneCtrCheckboxSelected = true;
    } else {
      this.isOnlyOneCtrCheckboxSelected = false;
    }
    this.isAnyCtrCheckboxSelected = true;
    this.areAllCtrCheckboxesSelected = true;
  }

  public unselectAllCtrs(): void {
    for (const key in this.checkboxStates) {
      this.checkboxStates[key] = false;
    }
    this.isAnyCtrCheckboxSelected = false;
    this.isOnlyOneCtrCheckboxSelected = false;
    this.areAllCtrCheckboxesSelected = false;
  }

  openCtrDetailedInfoDialog(): void {
    this.googleAnalyticsService.event(
      'click_show_ctr_calendarization_detailed_info',
      'calendarization'
    );
    this.checkedCtrs$.pipe(take(1)).subscribe((checkedCtr) => {
      if (checkedCtr.length !== 1) {
        console.error('Only one CTR can be selected for detailed view');
      }
      this.dialog.open<
        CalendarizationDetailedViewComponent,
        CalendarizationDetailedDialogData,
        void
      >(CalendarizationDetailedViewComponent, {
        height: '100%',
        width: '100%',
        position: { right: '0px', bottom: '0px' },
        data: {
          checkedCtr: checkedCtr[0],
          isRequestorMode: this.isRequestorMode,
        },
      });
    });
  }

  exportSelectedCtrToXls(): void {
    this.googleAnalyticsService.event(
      'click_export_ctr_calendarization_excel',
      'calendarization'
    );
    this.checkedCtrs$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef),
        switchMap((checkedCtrs) =>
          this.mediator.sendWithBlobResponse<CTRExcelQuery>('CTRExcelQuery', {
            id: checkedCtrs[0].scenarioCTRId,
          })
        )
      )
      .subscribe();
  }

  exportSelectedCtrToClientExcel(): void {
    this.googleAnalyticsService.event(
      'click_export_ctr_calendarization_client_excel',
      'calendarization'
    );
    combineLatest([this.scenarioId$, this.checkedCtrs$])
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef),
        switchMap(([scenarioId, checkedCtrs]) =>
          this.mediator.sendWithBlobResponse<ScenarioCTRsClientExcelQuery>(
            'ScenarioCTRsClientExcelQuery',
            {
              scenarioCTRIds: checkedCtrs.map((x) => x.scenarioCTRId),
              scenarioId: scenarioId,
            }
          )
        )
      )
      .subscribe();
  }

  openCalendarizationInfoDialog(): void {
    this.dialog.open(HoursDistributionInfoDialogComponent);
  }

  public selectedViewChanged(newView: CalendarizationViewType): void {
    this.googleAnalyticsService.event(
      `click_select_chart_${newView}_view`,
      'calendarization'
    );
    this.selectedView = newView;
  }

  public getTitleUnits(): string {
    return this.selectedView === CalendarizationViewType.FTE ? 'FTEs' : 'Hours';
  }

  getCtrTotalsInPeriod(month: MonthData): number {
    return this.selectedView === CalendarizationViewType.FTE
      ? Math.round(month.totalFte * 10) / 10
      : Math.round(month.totalHours);
  }

  getMaxEndYear(ctrs: CalendarizationForProject[]): number {
    return Math.max(...ctrs.map((ctr) => ctr.endDateAsDate.getFullYear()));
  }
  getMinStartYear(ctrs: CalendarizationForProject[]): number {
    return Math.min(...ctrs.map((ctr) => ctr.startDateAsDate.getFullYear()));
  }
}
