import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '@shared/shared.module';

import { ScenarioComparisonCellComponent } from './scenario-comparison-cell/scenario-comparison-cell.component';
import { ScenarioComparisonColumnComponent } from './scenario-comparison-column/scenario-comparison-column.component';
import { ScenarioComparisonComponent } from './scenario-comparison.component';
import { ScenarioCtrsSummaryComponent } from './scenario-ctrs-summary/scenario-ctrs-summary.component';
import { ScenarioComparisonEffects } from './store/scenario-comparison.effects';
import { FEATURE_KEY, reducer } from './store/scenario-comparison.reducer';

@NgModule({
    exports: [
        ScenarioComparisonCellComponent,
        ScenarioCtrsSummaryComponent,
        ScenarioComparisonComponent,
        ScenarioComparisonColumnComponent,
    ],
    imports: [
        StoreModule.forFeature(FEATURE_KEY, reducer),
        EffectsModule.forFeature([ScenarioComparisonEffects]),
        SharedModule,
        ScenarioComparisonCellComponent,
        ScenarioCtrsSummaryComponent,
        ScenarioComparisonComponent,
        ScenarioComparisonColumnComponent,
    ],
    providers: [DatePipe],
})
export class ScenarioComparisonModule {}
