import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { APP_DATE_FORMATS, AppDateAdapter } from '@app/DateAdapter';
import { selectSegmentPxDsFactory } from '@app/collections/pxds/store/pxds.selectors';
import { MediatorService } from '@app/mediator.service';
import { ActivitiesExcelQuery } from '@app/models/backendModel';
import { selectAllProjectNames } from '@collections/projects-names/store/projects-names.selectors';
import { deepClone, formToServerDateConversion } from '@common/utils';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'activities-export',
    templateUrl: './activities-export.component.html',
    styleUrls: ['./activities-export.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MatRadioModule,
        NgFor,
        MatSlideToggleModule,
        MatLegacyFormFieldModule,
        MatChipsModule,
        MatIconModule,
        MatLegacyInputModule,
        MatLegacyAutocompleteModule,
        MatLegacyOptionModule,
        MatDatepickerModule,
        MatButtonModule,
    ],
})
export class ActivitiesExportComponent implements OnInit {
  registerForm: UntypedFormGroup;
  maxDateVal = new Date();
  showError = false;
  selectionForm: UntypedFormGroup;
  roleTypes: any[] = [];
  businessSegment: any;
  pxdArr: any = [];
  projectArr: any = [];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  pxdCtrl = new UntypedFormControl();
  projectCtrl = new UntypedFormControl();
  ctrList: any;
  ctrListCopy: any;
  ctrIdArr: any = [];
  projectList: any;
  projectListCopy: any;
  projectIdArr: any = [];
  surfBusinessSegmentId = 1; // hardcoding business segmentId to SURF to meet criteria from ECTR-1642

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly mediatorService: MediatorService,
    private readonly store: Store
  ) {
    this.store
      .select(selectSegmentPxDsFactory(this.surfBusinessSegmentId))
      .pipe(take(1))
      .subscribe((pxddata) => {
        this.ctrList = deepClone(pxddata);
        this.ctrListCopy = deepClone(pxddata);
      });
    this.store
      .select(selectAllProjectNames)
      .pipe(take(1))
      .subscribe((projects) => {
        projects.sort((a, b) => a.projectName.localeCompare(b.projectName));
        this.projectList = deepClone(projects);
        this.projectListCopy = deepClone(projects);
      });
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      includeInProgressCtrs: false,
    });

    const currentDate = new Date();
    const defaultFromDate = new Date();
    defaultFromDate.setMonth(currentDate.getMonth() - 3);

    this.registerForm.get('fromDate').setValue(defaultFromDate);
    this.registerForm.get('toDate').setValue(currentDate);
  }

  selected(event): void {
    this.pxdArr.push(event.option.viewValue);
    const index = this.ctrList.findIndex(
      (x) => x.shortName == event.option.viewValue
    );
    this.ctrIdArr.push({
      id: this.ctrList[index].id,
      name: this.ctrList[index].shortName,
    });
    this.pxdCtrl.setValue(null);
  }

  selectedProject(event): void {
    this.projectArr.push(event.option.viewValue);
    const index = this.projectList.findIndex(
      (x) => x.projectName == event.option.viewValue
    );
    this.projectIdArr.push({
      id: this.projectList[index].id,
      name: this.projectList[index].projectName,
    });
    this.projectCtrl.setValue(null);
  }

  remove(item: string): void {
    const index = this.pxdArr.indexOf(item);
    const index1 = this.ctrIdArr.findIndex((x) => x.name == this.pxdArr[index]);
    if (index1 != -1) {
      this.ctrIdArr.splice(index, 1);
    }
    if (index >= 0) {
      this.pxdArr.splice(index, 1);
    }
  }

  removeProject(item: string): void {
    const index = this.projectArr.indexOf(item);
    const index1 = this.projectIdArr.findIndex(
      (x) => x.name == this.projectArr[index]
    );
    if (index1 != -1) {
      this.projectIdArr.splice(index, 1);
    }
    if (index >= 0) {
      this.projectArr.splice(index, 1);
    }
  }

  fieldEdited(event) {
    const val = event.target.value;
    if (val != null && val.length > 0 && val.trim().length > 0) {
      this.ctrList = [];
      this.ctrListCopy.forEach((ctr) => {
        if (ctr.shortName.toLowerCase().startsWith(val.toLowerCase())) {
          this.ctrList.push(ctr);
        }
      });
    } else {
      this.ctrList = this.ctrListCopy;
    }
  }

  projectFieldEdited(event) {
    const val = event.target.value;
    if (val != null && val.length > 0 && val.trim().length > 0) {
      this.projectList = [];
      this.projectListCopy.forEach((project) => {
        if (project.projectName.toLowerCase().startsWith(val.toLowerCase())) {
          this.projectList.push(project);
        }
      });
    } else {
      this.projectList = this.projectListCopy;
    }
  }

  add(event): void {
    const input = event.input;
    const value = event.value;

    this.pxdArr.push(value.trim());

    if (input) {
      input.value = '';
    }
    this.pxdCtrl.setValue(null);
  }

  addProject(event): void {
    const input = event.input;
    const value = event.value;

    this.projectArr.push(value.trim());

    if (input) {
      input.value = '';
    }
    this.projectCtrl.setValue(null);
  }

  onDownload(): void {
    this.registerForm.markAllAsTouched();
    if (!this.registerForm.valid) {
      return;
    }
    const ctrIdsToExport = [];
    const projectIdsToExport = [];

    this.projectIdArr.forEach((project) => {
      projectIdsToExport.push(project.id);
    });
    this.ctrIdArr.forEach((ctr) => {
      ctrIdsToExport.push(ctr.id);
    });

    let fromDate = this.registerForm.get('fromDate')?.value;
    let toDate = this.registerForm.get('toDate')?.value;

    // defaulting fromDate to distant date and toDate to 1 year from now
    // to include all CTRs ever created (ECTR-1642)
    if (fromDate == null) fromDate = new Date(1949, 8, 2);

    if (toDate == null) {
      toDate = new Date();
      toDate.setFullYear(toDate.getFullYear() + 1);
    }

    this.mediatorService
      .sendWithBlobResponse<ActivitiesExcelQuery>('ActivitiesExcelQuery', {
        from: formToServerDateConversion(fromDate),
        to: formToServerDateConversion(toDate),
        ctrIdList: ctrIdsToExport,
        projectIdList: projectIdsToExport,
        businessSegmentId: this.surfBusinessSegmentId,
        includeInProgressCTRs: this.registerForm.get('includeInProgressCtrs')
          ?.value,
      })
      .subscribe();
  }
}
